import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import { Column, Order } from '../../../types';
import Header from './Header';
import Body from './Body';
import { Theme, createStyles } from '@material-ui/core';
import TableToolbar from './Toolbar';
import { stableSort } from '../../../utils/functions';
import zipcelx, { ZipCelXConfig, ZipCelXRow } from 'zipcelx';
import { useAlert } from '../../../context/alert';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    container: {
      borderRadius: '0px 0px 4px 4px',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '60vh',
      },
      maxHeight: '75vh',
    },
  })
);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export interface TableAction {
  label: string;
  icon: JSX.Element;
  handleClick: () => void;
}

interface ReportTable<T extends { [key: string]: string | number | undefined }> {
  columns: Column<T>[];
  data?: T[];
  loading: boolean;
  newFiltersApplied: boolean;
  title: string;
  totals?: T;
  actions?: TableAction[];
  clickeable?: boolean;
  onRowClick?: (row: T) => void;
}

const ReportTable = <T extends { [key: string]: string | number | undefined }>({
  columns,
  data,
  loading,
  newFiltersApplied,
  title,
  totals,
  actions,
  clickeable,
  onRowClick,
}: ReportTable<T>) => {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof T>('country');
  const { showAlert } = useAlert();
  const { t } = useTranslation();

  const getExcelData = (): ZipCelXRow[] => {
    const header: ZipCelXRow[] = [];
    header.push(columns.map(column => ({ value: column.label, type: 'string' })));
    const rows: ZipCelXRow[] = [];
    data &&
      data.forEach(row =>
        rows.push(
          columns.map(column => ({
            value:
              column.format && (column.field === 'time' || column.field === 'timeInterval')
                ? column.format(row)
                : row[column.field] || '0',
            type: column.type,
          }))
        )
      );
    const total: ZipCelXRow[] = [];
    totals &&
      total.push(
        columns.map(column => ({ value: totals[column.field] || ' - ', type: column.type }))
      );
    return [...header, ...total, ...rows];
  };

  const handleDownloadExcel = () => {
    const config: ZipCelXConfig = {
      filename: title,
      sheet: {
        data: getExcelData(),
      },
    };
    try {
      zipcelx(config);
    } catch (error) {
      showAlert(
        t('errors.excelExport.title'),
        t('errors.excelExport.body') + (error as Error).message
      );
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper className={classes.root}>
      <TableToolbar
        title={title}
        handleDownloadExcel={handleDownloadExcel}
        isEmpty={!data}
        actions={actions}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="report-table" size="small" id="report-table">
          <Header
            columns={columns}
            totals={totals}
            order={order}
            orderBy={orderBy as string}
            onRequestSort={handleRequestSort}
          />
          <Body
            data={
              data
                ? (stableSort(
                    data as { [key in keyof T]: string | number }[],
                    getComparator(order, orderBy)
                  ) as T[])
                : []
            }
            loading={loading}
            columns={columns}
            newFiltersApplied={newFiltersApplied}
            clickeable={clickeable}
            onRowClick={onRowClick}
          />
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ReportTable;
