import React from 'react';
import { FormControlLabel, Checkbox, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useReport } from '../../../context/report';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
      minWidth: 140,
    },
  })
);

const DisableToDateCheckbox = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { filters, updateFilters } = useReport();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    updateFilters({ ...filters, disableToDate: checked });
  };

  return (
    <FormControlLabel
      className={classes.formControl}
      control={<Checkbox checked={filters.disableToDate} onChange={handleChange} name="checkedA" />}
      label={t('shared.labels.disableToDate')}
    />
  );
};

export default DisableToDateCheckbox;
