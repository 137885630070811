import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useReport } from '../../../context/report';

interface Props {
  value?: string;
  disabled?: boolean;
}

const ServiceSelector = ({ value, disabled }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { filters, updateFilters } = useReport();

  const handleChange = (value: string) => {
    updateFilters({ ...filters, service: value });
  };

  return (
    <FormControl className={classes.formControl} disabled={disabled}>
      <InputLabel id="select-label">{t('shared.labels.exclusionType')}</InputLabel>
      <Select
        labelId="select-label"
        id="exclusionType"
        value={value || filters.service || ''}
        label="exclusionType"
        onChange={event => handleChange(event.target.value as string)}
      >
        <MenuItem value={'MFY'}>{'MFY'}</MenuItem>
        <MenuItem value={'DT'}>{'OEPE'}</MenuItem>
        <MenuItem value={'FC'}>{'R2P'}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ServiceSelector;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
  })
);
