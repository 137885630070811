import React from 'react';
import {
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Column } from '../../../types/Table';

interface Body<T extends { [key: string]: string | number | undefined }> {
  data: T[];
  loading: boolean;
  columns: Column<T>[];
  newFiltersApplied: boolean;
  clickeable?: boolean;
  onRowClick?: (row: T) => void;
}

const Body = <T extends { [key: string]: string | number | undefined }>({
  data,
  loading,
  columns,
  newFiltersApplied,
  clickeable,
  onRowClick,
}: Body<T>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleRowClick = (row: T) => {
    if (clickeable && onRowClick) onRowClick(row);
  };

  if (loading) {
    return (
      <TableBody>
        <TableRow>
          <TableCell align="center" colSpan={9}>
            <CircularProgress size={14} />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (data.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell align={smDown ? 'left' : 'center'} colSpan={9}>
            {newFiltersApplied ? (
              <Typography variant="body1">
                {t('driveThruRankings.labels.newFiltersApplied')}
              </Typography>
            ) : (
              <Typography variant="body1">{t('driveThruRankings.labels.noData')}</Typography>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  } else {
    return (
      <TableBody>
        {data.map((row, index) => (
          <TableRow
            hover
            role="checkbox"
            key={`row-${index}`}
            onClick={() => handleRowClick(row)}
            style={{ cursor: clickeable ? 'pointer' : 'inherit' }}
          >
            {columns.map((column, columnIndex) => (
              <TableCell align={column.align} key={`row-${index}-column-${columnIndex}`}>
                {column.format ? column.format(row) : row[column.field]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  }
};

export default Body;
