import React, { useEffect } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  CircularProgress,
  Hidden,
  ButtonGroup,
  Tooltip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useReport } from '../../context/report';
import { green } from '@material-ui/core/colors';
import { useLocation } from 'react-router-dom';
import AutoSelect from './AutoSelect';
import { useInstitutional } from '../../context/institutional';
import DateRangePicker from './DateRangePicker';
import SegmentSelector from './SegmentSelector';
import GroupSelector from './selectors/GroupSelector';
import DivisionSelector from './selectors/DivisionSelector';
import CountrySelector from './selectors/CountrySelector';
import FrequencySelector from './selectors/FrequencySelector';
import OperativeFilters from './dialogs/opstructure-filters';
import { useUser } from '../../context/user';
import DashboardSelector from './selectors/DashboardSelector';
import ReportSelector from './selectors/ReportSelector';
import { useDashboard } from '../../context/dashboard';
import { CloudDownload } from '@material-ui/icons';
import CompanyFilters from './dialogs/company-filters';
import {
  GROUPBY_DEFAULT,
  GROUPBY_ANNUAL,
  GROUPBY_REPORT,
  GROUPBY_OPSTRUCTURE,
} from '../../utils/constants';
import AnnualFromTo from './dialogs/annual-from-to';
import ServiceSelector from './selectors/ServiceSelector';
import { isAfter, isBefore, isSameDay, subDays, subMonths } from 'date-fns';
import { ReportGrouping } from '../../types';
import DisableToDateCheckbox from './selectors/DisableToDateCheckbox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 90,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      marginBottom: theme.spacing(0),
    },
    grow: {
      flexGrow: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    loadingWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
  })
);

interface Toolbar {
  getData: () => Promise<void>;
  requestExport?: () => Promise<void>;
  handleAddExclusion?: () => void;
  dataIsLoading: boolean;
}

const Toolbar = ({ getData, requestExport, handleAddExclusion, dataIsLoading }: Toolbar) => {
  const { filters, updateFilters, updateFrequency } = useReport();
  const { loading: loadingInstitutionalData, filteredStores } = useInstitutional();
  const { cleanDashboard, handleApplied } = useDashboard();
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { division, groupBy, disableToDate } = filters;
  const { permissions } = useUser();
  const [dateError, setDateError] = React.useState(false);

  const canFilterOperativeStructure = permissions.includes('FILTER_OPSTRUCTURE');
  const canExportReport =
    location.pathname.includes('report') && permissions.includes('EXPORT_REPORT');
  const inRanking = location.pathname.includes('rankings');
  const inCharts = location.pathname.includes('charts');
  const inDashboard = location.pathname.includes('dashboard');
  const inDetailedDashboard = location.pathname.includes('dashboard/detailed');
  const inAnnual = location.pathname.includes('annual');
  const inExclusions = location.pathname.includes('exclusions');
  const loading = loadingInstitutionalData || dataIsLoading;
  const missingGrouping = groupBy.length === 0;
  const missingDivision = division.length === 0;
  const missingCountry = filters.countries.length === 0;
  const missingYear = filters.year === '' || filters.year === undefined;
  const disableFetch =
    ((missingDivision || missingGrouping) && !inRanking) ||
    ((inRanking || inCharts || inDetailedDashboard || inExclusions) && missingCountry) ||
    (inAnnual && missingYear) ||
    dateError ||
    loading;

  const inSameDay = isSameDay(filters.fromDate, filters.toDate);
  const showSegments = !(
    inDashboard ||
    inAnnual ||
    (inExclusions && (!inSameDay || disableToDate))
  );

  const getOptions = () => {
    if (inAnnual) {
      if (canFilterOperativeStructure) return GROUPBY_DEFAULT.concat(GROUPBY_ANNUAL);
      return GROUPBY_DEFAULT;
    } else {
      if (canFilterOperativeStructure)
        return GROUPBY_DEFAULT.concat(GROUPBY_REPORT).concat(GROUPBY_OPSTRUCTURE);
      return GROUPBY_DEFAULT.concat(GROUPBY_REPORT);
    }
  };

  useEffect(() => {
    const options = getOptions();
    const gbe: ReportGrouping[] = ['country', 'store', 'businessDate', 'time'];
    const yesterday = subDays(new Date(), 1);
    const lastMonth = subMonths(yesterday, 1);
    const groupBy = inExclusions ? gbe : filters.groupBy.filter(e => options.includes(e));
    if (inExclusions) {
      updateFrequency('beginTime');
      if (
        isAfter(filters.fromDate, yesterday) ||
        isBefore(filters.fromDate, lastMonth) ||
        isAfter(filters.toDate, yesterday)
      ) {
        updateFilters({
          ...filters,
          division: filters.division.length > 1 ? [] : filters.division,
          stores: filters.stores.length > 1 ? [] : filters.stores,
          fromDate: yesterday,
          toDate: yesterday,
          groupBy,
        });
      } else {
        updateFilters({
          ...filters,
          division: filters.division.length > 1 ? [] : filters.division,
          stores: filters.stores.length > 1 ? [] : filters.stores,
          groupBy,
        });
      }
    } else if (inAnnual) {
      updateFilters({ ...filters, groupBy, disableToDate: false });
    } else if (!inDashboard && !inAnnual && filters.division.length > 1) {
      updateFilters({ ...filters, division: [], groupBy, disableToDate: false });
    } else if (inRanking && filters.stores.length > 0 && filteredStores.length < 2) {
      updateFilters({ ...filters, stores: [], groupBy, disableToDate: false });
    } else if (filters.stores.length === 0 && filteredStores.length < 2) {
      updateFilters({ ...filters, stores: [...filteredStores], groupBy, disableToDate: false });
    } else updateFilters({ ...filters, disableToDate: false });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    inDashboard && cleanDashboard();
    // eslint-disable-next-line
  }, [filters.division, filters.countries, filters.stores]);

  const handleApplyFilters = async () => {
    inDashboard && handleApplied(true);
    await getData();
  };

  return (
    <Hidden implementation="css" mdDown>
      <Grid container item xs={12} className={classes.root} direction="column">
        {!inRanking && !inCharts && !inDashboard && !inExclusions && (
          <Grid container item xs={12} direction="row">
            <GroupSelector />
            {groupBy.includes('time') && <FrequencySelector />}
            <ReportSelector />
          </Grid>
        )}
        {inExclusions && (
          <Grid container item xs={12} direction="row">
            <ServiceSelector />
            <DisableToDateCheckbox />
            <div className={classes.grow} />
            <div className={classes.loadingWrapper}>
              <ButtonGroup disabled={loading}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleApplyFilters}
                  disabled={disableFetch}
                >
                  {t('reports.labels.search')}
                </Button>
                <Button color="primary" variant="contained" onClick={handleAddExclusion}>
                  {`+ ${t('reports.labels.addExclusion')}`}
                </Button>
              </ButtonGroup>
              {loading && <CircularProgress size={22} className={classes.buttonProgress} />}
            </div>
          </Grid>
        )}
        <Grid container item xs={12} direction="row">
          <DivisionSelector />
          <CountrySelector />
          <AutoSelect singleValue={inExclusions} />
          {!inDashboard && !inAnnual && (
            <DateRangePicker error={dateError} setError={setDateError} />
          )}
          {showSegments && <SegmentSelector />}
          {inAnnual && <AnnualFromTo />}
          {inDashboard && <DashboardSelector />}
          {canFilterOperativeStructure && !inExclusions && <OperativeFilters />}
          {canFilterOperativeStructure && !inExclusions && <CompanyFilters />}
          <div className={classes.grow} />
          {!inExclusions && (
            <div className={classes.loadingWrapper}>
              <ButtonGroup disabled={disableFetch}>
                <Button
                  id="toolbar-apply"
                  color="primary"
                  variant="contained"
                  onClick={handleApplyFilters}
                >
                  {t('reports.labels.apply')}
                </Button>
                {canExportReport && (
                  <Tooltip title={t('reports.labels.saveInOneDrive').toString()}>
                    <Button
                      id="toolbar-onedrive"
                      color="primary"
                      variant="contained"
                      onClick={requestExport}
                    >
                      <CloudDownload />
                    </Button>
                  </Tooltip>
                )}
              </ButtonGroup>
              {loading && <CircularProgress size={22} className={classes.buttonProgress} />}
            </div>
          )}
        </Grid>
      </Grid>
    </Hidden>
  );
};

export default Toolbar;
