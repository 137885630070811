import React, { ReactElement } from 'react';
import { useScrollTrigger, Slide } from '@material-ui/core';

interface HideOnScroll {
  children: ReactElement;
}

const HideOnScroll = ({ children }: HideOnScroll) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="up" in={!trigger}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
