import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Theme,
  createStyles,
  Grid,
  IconButton,
  Typography,
  TextField,
  FormControl,
  Slider,
  Mark,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useReport } from '../../../context/report';
import CalendarIcon from '@material-ui/icons/DateRange';
import { MONTHS } from '../../../utils/constants';
import { endOfMonth } from 'date-fns';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: 155,
      marginRight: theme.spacing(4),
    },
    iconButton: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
    descriptionContent: {
      width: '100%',
      textAlign: 'center',
      background: theme.palette.primary.main,
    },
    description: {
      color: '#FFFFFF',
    },
    years: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      background: theme.palette.primary.main,
      paddingBottom: '0px !important',
    },
    yearText: {
      color: 'rgba(0, 0, 0, 0.54)',
      padding: theme.spacing(1),
    },
    yearSelected: {
      color: '#FFFFFF',
      padding: theme.spacing(1),
      fontWeight: 900,
    },
    mainContent: {
      padding: '0px !important',
      margin: 0,
      overflow: 'hidden',
      borderRadius: '20px !important',
    },
    content: {
      padding: '0px !important',
      margin: 0,
      overflow: 'hidden',
      width: 600,
    },
  })
);

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const AnnualFromTo = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { filters, updateFilters } = useReport();
  const [openDialog, setOpenDialog] = useState(false);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [year, setYear] = useState<number>(parseInt(filters.year));
  const [selection, setSelection] = useState<number[]>([
    filters.fromAnnualDate.getMonth(),
    filters.toAnnualDate.getMonth(),
  ]);

  const marks: Mark[] = MONTHS.map((m, index) => ({
    value: index,
    label: markLabel(m, index),
  }));

  function markLabel(value: string, index: number) {
    const label = t(`reports.labels.${value.toLowerCase()}`).slice(0, 3);
    let labelColor: 'textPrimary' | 'textSecondary' = 'textPrimary';
    if (year === currentYear && index > currentMonth) labelColor = 'textSecondary';

    return (
      <Box marginY={1}>
        <Typography variant="caption" color={labelColor}>
          {label}
        </Typography>
      </Box>
    );
  }

  const renderValue = () => {
    const fromMonth = t(
      `reports.labels.${MONTHS[filters.fromAnnualDate.getMonth()].toLowerCase()}`
    ).slice(0, 3);
    const toMonth = t(
      `reports.labels.${MONTHS[filters.toAnnualDate.getMonth()].toLowerCase()}`
    ).slice(0, 3);
    const between = t('reports.labels.to');
    return `${year} - ${fromMonth} ${between} ${toMonth}`;
  };

  const renderDescription = () => {
    const fromMonth = t(`reports.labels.${MONTHS[selection[0]].toLowerCase()}`);
    const toMonth = t(`reports.labels.${MONTHS[selection[1]].toLowerCase()}`);
    const between = t('reports.labels.to');
    return `${fromMonth} ${between} ${toMonth}, ${year}`;
  };

  const handleYear = (value: number) => {
    setYear(value);
    if (value === currentYear) {
      let from = selection[0] > currentMonth ? currentMonth : selection[0];
      let to = selection[1] > currentMonth ? currentMonth : selection[1];
      setSelection([from, to]);
    }
  };

  const handleSelection = (event: React.ChangeEvent<{}>, value: number | number[]) => {
    if (year === currentYear && (value as number[])[1] > currentMonth) {
      return;
    } else {
      setSelection(value as number[]);
    }
  };

  const handleSubmit = () => {
    const fromAnnualDate = new Date(year, selection[0], 1);
    const toAnnualDate = endOfMonth(new Date(year, selection[1], 1));
    updateFilters({ ...filters, year: year.toString(), fromAnnualDate, toAnnualDate });
    setOpenDialog(false);
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <TextField
          id="annual-date-input-from-to"
          type="button"
          label={`${t('shared.labels.from')} - ${t('shared.labels.to')}`}
          value={renderValue()}
          disabled
          InputProps={{
            endAdornment: (
              <IconButton
                id="annual-date-button-from-to"
                onClick={() => setOpenDialog(true)}
                className={classes.iconButton}
              >
                <CalendarIcon />
              </IconButton>
            ),
          }}
        />
      </FormControl>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="responsive-extraFilter-dialog"
        className={classes.mainContent}
        maxWidth="md"
      >
        <DialogContent className={classes.content}>
          <Grid container spacing={1} justify={'center'}>
            <Grid item xs={12} className={classes.years}>
              <IconButton
                id="annual-icon-button-year"
                onClick={() => handleYear(currentYear - 1)}
                className={classes.iconButton}
              >
                <Typography
                  variant="body2"
                  className={year === currentYear - 1 ? classes.yearSelected : classes.yearText}
                >
                  {currentYear - 1}
                </Typography>
              </IconButton>
              <IconButton
                id="annual-icon-button-year-2"
                onClick={() => handleYear(currentYear)}
                className={classes.iconButton}
              >
                <Typography
                  variant="h6"
                  className={year === currentYear ? classes.yearSelected : classes.yearText}
                >
                  {currentYear}
                </Typography>
              </IconButton>
            </Grid>
            <Grid item xs={12} className={classes.descriptionContent}>
              <Typography variant="h6" className={classes.description}>
                {renderDescription()}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Box mt={1}>
                <PrettoSlider
                  id="annual-month-slider"
                  value={selection}
                  onChange={handleSelection}
                  marks={marks}
                  step={1}
                  min={0}
                  max={11}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="annual-button-apply" color="default" onClick={handleSubmit}>
            {t('shared.buttons.apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AnnualFromTo;
