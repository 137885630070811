import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../../../Router';

const Report = lazy(() => import('./report/router'));
const Charts = lazy(() => import('./charts'));
const Rankings = lazy(() => import('./rankings'));

const FrontCounterRouter = () => (
  <Switch>
    <PrivateRoute path="/frontcounter/report" component={Report} />
    <PrivateRoute path="/frontcounter/charts" component={Charts} />
    <PrivateRoute path="/frontcounter/rankings" component={Rankings} />
    <Redirect from="/frontcounter" to="/frontcounter/report" />
  </Switch>
);

export default FrontCounterRouter;
