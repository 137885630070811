import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

function Copyright() {
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.arcosdorados.com/">
          Arcos Dorados
        </Link>{' '}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(1, 1),
    [theme.breakpoints.up('xl')]: {
      position: 'absolute',
      bottom: 0,
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        <Copyright />
      </Container>
    </footer>
  );
};

export default Footer;
