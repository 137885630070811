/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  TextField,
  Checkbox,
  FormControl,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import Autocomplete, { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 130,
    },
  })
);

interface AutocompleteSelector<T> {
  label?: string;
  value: T[];
  options: T[];
  disabled?: boolean;
  fullWidth?: boolean;
  groupBy?: (option: T) => string;
  handleSelect: (newSelection: T[]) => void;
  renderOption: (option: T) => string;
  renderGroup?: (params: AutocompleteRenderGroupParams) => ReactNode;
}

const AutocompleteSelector = <T,>({
  value,
  label,
  disabled,
  options,
  fullWidth,
  groupBy,
  renderOption,
  renderGroup,
  handleSelect,
}: AutocompleteSelector<T>) => {
  const { t } = useTranslation();
  const classes = useStyles();

  // const filterOptions = createFilterOptions({
  //   matchFrom: 'any',
  //   ignoreCase: true,
  //   limit: 5,
  //   stringify: (option: T) => renderOption(option),
  // });

  return (
    <Autocomplete
      id={`autoselect-multiple-${label}`}
      multiple
      autoComplete
      fullWidth={fullWidth}
      includeInputInList
      disableCloseOnSelect
      disabled={disabled}
      groupBy={groupBy}
      renderGroup={renderGroup}
      value={value}
      options={options}
      onChange={(event, newValue) => {
        handleSelect(newValue);
      }}
      renderTags={tags => tags.map(tag => renderOption(tag)).join()}
      noOptionsText={t('toolbar.labels.noOptionsText')}
      // filterOptions={filterOptions}
      getOptionLabel={option => renderOption(option)}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 3, marginLeft: 0 }}
            checked={selected}
            size="small"
          />
          {renderOption(option)}
        </React.Fragment>
      )}
      renderInput={params => (
        <FormControl className={classes.formControl} fullWidth={fullWidth}>
          <TextField {...params} variant="standard" label={label} />
        </FormControl>
      )}
    />
  );
};

export default AutocompleteSelector;
