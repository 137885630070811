import React, { lazy, Suspense } from 'react';
import { PrivateRoute } from '../../../Router';
import { Switch } from 'react-router-dom';
import LoadingPage from '../../shared/LoadingPage';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../shared/ErrorFallback';
import { InstitutionalProvider } from '../../../context/institutional';
import { MadeForYouProvider } from '../../../context/mfy';
import { DriveThruProvider } from '../../../context/drive-thru';
import { FrontCounterProvider } from '../../../context/front-counter';
import DriveThruRouter from '../../reports/DriveThru';
import MadeForYouRouter from '../../reports/MadeForYou';
import FrontCounterRouter from '../../reports/FrontCounter';
import Exclusions from '../../reports/Exclusions/report';

const Profile = lazy(() => import('../../profile'));
const Help = lazy(() => import('../../help'));
const Dashboard = lazy(() => import('../../dashboard'));

const ContentRouter = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        <InstitutionalProvider>
          <MadeForYouProvider>
            <DriveThruProvider>
              <FrontCounterProvider>
                <PrivateRoute
                  path={['/dashboard', '/dashboard/general', '/dashboard/detailed']}
                  component={Dashboard}
                />
                <PrivateRoute
                  path={['/madeforyou', '/madeforyou/report', '/madeforyou/charts']}
                  component={MadeForYouRouter}
                />
                <PrivateRoute
                  path={[
                    '/drivethru',
                    '/drivethru/report',
                    '/drivethru/charts',
                    '/drivethru/ranking',
                  ]}
                  component={DriveThruRouter}
                />
                <PrivateRoute
                  path={['/frontcounter', '/frontcounter/report', '/frontcounter/charts']}
                  component={FrontCounterRouter}
                />
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute path="/help" component={Help} />
                <PrivateRoute path={['/exclusions']} component={Exclusions} />
              </FrontCounterProvider>
            </DriveThruProvider>
          </MadeForYouProvider>
        </InstitutionalProvider>
      </Switch>
    </Suspense>
  </ErrorBoundary>
);

export default ContentRouter;
