import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  MenuItem,
  Checkbox,
  ListItemText,
  Popover,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { useReport } from '../../context/report';
import { hourlySegments, quarterlySegments } from '../../utils/segments';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List } from 'react-window';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 90,
    },
    input: {
      cursor: 'pointer',
    },
  })
);

const SegmentSelector = ({ noLabel, fullWidth }: { noLabel?: boolean; fullWidth?: boolean }) => {
  const classes = useStyles();
  const { frequency, filters, updateFilters } = useReport();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const segmentOptions = frequency === 'hour' ? hourlySegments : quarterlySegments;
  const { segments } = filters;

  const handleOptionClick = (value: string) => {
    if (value === 'ALL') {
      if (segments.length === segmentOptions.length) {
        updateFilters({ ...filters, segments: [] });
      } else {
        updateFilters({ ...filters, segments: segmentOptions });
      }
    } else {
      const newSegments = [...segments];
      const index = newSegments.indexOf(value);
      if (index > -1) {
        newSegments.splice(index, 1);
      } else {
        newSegments.push(value);
      }
      updateFilters({ ...filters, segments: newSegments });
    }
  };

  const handleOpenList = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const renderInputValue = (): string => {
    if (segmentOptions.length === segments.length) {
      return t('toolbar.labels.all');
    } else {
      return `${segments.length} ${t('toolbar.labels.selected')}`;
    }
  };

  return (
    <FormControl className={classes.formControl} fullWidth={fullWidth}>
      <div className={classes.input}>
        <TextField
          id="segments-select"
          label={!noLabel && t('toolbar.labels.schedule')}
          value={renderInputValue()}
          fullWidth={fullWidth}
          onClick={handleOpenList}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </InputAdornment>
            ),
            readOnly: true,
            style: { cursor: 'pointer' },
          }}
        />
      </div>
      <Popover open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        <MenuItem value={'ALL'} onClick={() => handleOptionClick('ALL')}>
          <Checkbox checked={segments.length === segmentOptions.length} />
          <ListItemText primary={t('shared.labels.all')} />
        </MenuItem>
        <List height={150} width={200} itemCount={segmentOptions.length} itemSize={55}>
          {({ index, style }) => (
            <MenuItem
              style={style}
              value={segmentOptions[index]}
              onClick={() => handleOptionClick(segmentOptions[index])}
            >
              <Checkbox checked={segments.indexOf(segmentOptions[index]) > -1} />
              <ListItemText primary={segmentOptions[index]} />
            </MenuItem>
          )}
        </List>
      </Popover>
    </FormControl>
  );
};

export default SegmentSelector;
