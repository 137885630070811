import React, { createContext, ReactNode, useState, useContext, useEffect } from 'react';
import {
  Store,
  MasterData,
  RegionalManagement,
  Region,
  Management,
  Supervision,
  Country,
  Franchise,
  StoreAgreement,
} from '../types';
import { useReport } from './report';
import { useAlert } from './alert';
import { compareStores } from '../utils/functions';
import { useApi } from './api';
import { STORE_AGREEMENTS } from '../utils/constants';

interface InstitutionalContext {
  filteredDivisions: string[];
  filteredCountries: string[];
  filteredRegionalManagements: RegionalManagement[];
  filteredRegions: Region[];
  filteredManagements: Management[];
  filteredSupervisions: Supervision[];
  filteredStores: Store[];
  filteredFranchises: Franchise[];
  filteredAgreements: string[];
  loading: boolean;
}

const initialState: InstitutionalContext = {
  filteredDivisions: [],
  filteredCountries: [],
  filteredRegionalManagements: [],
  filteredRegions: [],
  filteredManagements: [],
  filteredSupervisions: [],
  filteredStores: [],
  filteredFranchises: [],
  filteredAgreements: [],
  loading: false,
};

const InstitutionalContext = createContext(initialState);

interface InstitutionalProvider {
  children: ReactNode;
}

export const InstitutionalProvider = ({ children }: InstitutionalProvider) => {
  const [loading, setLoading] = useState(false);
  const [masterData, setMasterData] = useState<MasterData | undefined>(undefined);
  const [filteredDivisions, setFilteredDivisions] = useState<string[]>([]);
  const [filteredCountries, setCountries] = useState<string[]>([]);
  const [filteredRegionalManagements, setFilteredRegionalManagements] = useState<
    RegionalManagement[]
  >([]);
  const [filteredRegions, setFilteredRegions] = useState<Region[]>([]);
  const [filteredManagements, setFilteredManagements] = useState<Management[]>([]);
  const [filteredSupervisions, setFilteredSupervisions] = useState<Supervision[]>([]);
  const [filteredStores, setStores] = useState<Store[]>([]);
  const [filteredFranchises, setFilteredFranchises] = useState<Franchise[]>([]);
  const [filteredAgreements, setFilteredAgreements] = useState<string[]>(STORE_AGREEMENTS);
  const { filters, updateFilters } = useReport();
  const {
    division,
    countries,
    regionalManagements,
    regions,
    managements,
    supervisions,
    franchises,
    storeAgreements,
  } = filters;
  const { showError } = useAlert();
  const { fetchOperativeStructure } = useApi();

  function formattedStores(stores: Store[]): Store[] {
    let result: Store[] = [];
    let mapping: { [key: string]: Store[] } = {};
    stores.forEach(s => {
      if (!mapping[s.country]) mapping[s.country] = [];
      mapping[s.country].push(s);
    });
    Object.keys(mapping).forEach(key => {
      result = result.concat(mapping[key]);
    });
    return result;
  }

  const filterBySupervision = () => {
    if (masterData) {
      setFilteredRegionalManagements(
        masterData.regionalManagements.filter(rm =>
          supervisions.map(s => s.regionalMgmtId).includes(rm.id)
        )
      );
      setFilteredRegions(
        masterData.regions.filter(r => supervisions.map(s => s.regionId).includes(r.id))
      );
      setFilteredManagements(
        masterData.managements.filter(m => supervisions.map(s => s.mgmtId).includes(m.id))
      );
      setStores(
        formattedStores(
          masterData.stores.filter(s => supervisions.map(sup => sup.id).includes(s.supervisionId))
        )
      );
    }
  };

  const filterByManagement = () => {
    if (masterData) {
      setFilteredRegionalManagements(
        masterData.regionalManagements.filter(rm =>
          managements.map(m => m.regionalMgmtId).includes(rm.id)
        )
      );
      setFilteredRegions(
        masterData.regions.filter(r => managements.map(m => m.regionId).includes(r.id))
      );
      setFilteredSupervisions(
        masterData.supervisions.filter(s => managements.map(m => m.id).includes(s.mgmtId))
      );
      setStores(
        formattedStores(
          masterData.stores.filter(s => managements.map(m => m.id).includes(s.mgmtId))
        )
      );
    }
  };

  const filterByRegion = () => {
    if (masterData) {
      setFilteredRegionalManagements(
        masterData.regionalManagements.filter(rm =>
          regions.map(r => r.regionalMgmtId).includes(rm.id)
        )
      );
      setFilteredSupervisions(
        masterData.supervisions.filter(s => regions.map(r => r.id).includes(s.regionId))
      );
      setFilteredManagements(
        masterData.managements.filter(m => regions.map(r => r.id).includes(m.regionId))
      );
      setStores(
        formattedStores(masterData.stores.filter(s => regions.map(r => r.id).includes(s.regionId)))
      );
    }
  };

  const filterByRegionalManagement = () => {
    if (masterData) {
      setFilteredSupervisions(
        masterData.supervisions.filter(s =>
          regionalManagements.map(m => m.id).includes(s.regionalMgmtId)
        )
      );
      setFilteredRegions(
        masterData.regions.filter(r =>
          regionalManagements.map(rm => rm.id).includes(r.regionalMgmtId)
        )
      );
      setFilteredManagements(
        masterData.managements.filter(m =>
          regionalManagements.map(rm => rm.id).includes(m.regionalMgmtId)
        )
      );
      setStores(
        formattedStores(
          masterData.stores.filter(s =>
            regionalManagements.map(rm => rm.id).includes(s.regionalMgmtId)
          )
        )
      );
    }
  };

  const filterByFranchise = () => {
    if (masterData) {
      setStores(
        formattedStores(
          masterData.stores.filter(s => franchises.map(f => f.id).includes(s.companyId))
        )
      );
      setFilteredAgreements(
        STORE_AGREEMENTS.filter(a =>
          franchises.map(f => f.agreementId?.toString() || 'NULL').includes(a)
        )
      );
    }
  };

  const filterByCountry = () => {
    if (masterData) {
      setFilteredRegionalManagements(
        masterData.regionalManagements.filter(r => countries.includes(r.country))
      );
      setFilteredRegions(masterData.regions.filter(r => countries.includes(r.country)));
      setFilteredManagements(masterData.managements.filter(m => countries.includes(m.country)));
      setFilteredSupervisions(masterData.supervisions.filter(s => countries.includes(s.country)));
      setStores(
        formattedStores(masterData.stores.filter(store => countries.includes(store.country)))
      );
      setFilteredFranchises(masterData.franchises.filter(f => countries.includes(f.country)));
      setFilteredAgreements(STORE_AGREEMENTS);
    }
  };

  const filterByDivision = () => {
    if (masterData) {
      setCountries(
        masterData.countries.filter(c => division.includes(c.divisionId)).map(c => c.label)
      );
      setFilteredRegionalManagements(
        masterData.regionalManagements.filter(r => division.includes(r.divisionId))
      );
      setFilteredRegions(masterData.regions.filter(r => division.includes(r.divisionId)));
      setFilteredManagements(masterData.managements.filter(m => division.includes(m.divisionId)));
      setFilteredSupervisions(masterData.supervisions.filter(s => division.includes(s.divisionId)));
      setStores(
        formattedStores(masterData.stores.filter(store => division.includes(store.divisionId)))
      );
      setFilteredFranchises(masterData.franchises.filter(f => division.includes(f.divisionId)));
      setFilteredAgreements(STORE_AGREEMENTS);
    }
  };

  const matchesCountry = (country: string) => {
    if (countries.length > 0) {
      return countries.includes(country);
    } else return true;
  };

  const matchesRegionalManagement = (id: string) => {
    if (regionalManagements.length > 0) {
      return regionalManagements.map(rm => rm.id).includes(id);
    } else return true;
  };
  const matchesRegion = (id: string) => {
    if (regions.length > 0) {
      return regions.map(r => r.id).includes(id);
    } else return true;
  };
  const matchesManagement = (id: string) => {
    if (managements.length > 0) {
      return managements.map(r => r.id).includes(id);
    } else return true;
  };
  const matchesSupervision = (id: string) => {
    if (supervisions.length > 0) {
      return supervisions.map(r => r.id).includes(id);
    } else return true;
  };
  const matchesAgreements = (id: number | null) => {
    if (storeAgreements.length > 0) {
      return storeAgreements.includes(id ? (id.toString() as StoreAgreement) : 'NULL');
    } else return true;
  };

  const filterByAgreement = () => {
    if (masterData) {
      const agreementFilteredStores = masterData.stores.filter(
        s =>
          division.includes(s.divisionId) &&
          matchesCountry(s.country) &&
          matchesRegionalManagement(s.regionalMgmtId) &&
          matchesRegion(s.regionId) &&
          matchesManagement(s.mgmtId) &&
          matchesSupervision(s.supervisionId) &&
          matchesAgreements(s.agreementId)
      );
      setStores(formattedStores(agreementFilteredStores));
      setFilteredFranchises(
        masterData.franchises.filter(
          f =>
            division.includes(f.divisionId) &&
            matchesCountry(f.country) &&
            matchesAgreements(f.agreementId)
        )
      );
    }
  };

  useEffect(() => {
    const getRegionalManagements = (masterDataResponse: Store[]): RegionalManagement[] => {
      const regionalManagements: RegionalManagement[] = [];
      masterDataResponse.forEach(store => {
        if (
          regionalManagements.findIndex(item => item.id === store.regionalMgmtId) === -1 &&
          store.regionalMgmtId !== null
        ) {
          regionalManagements.push({
            label: store.regionalMgmt,
            id: store.regionalMgmtId,
            divisionId: store.divisionId,
            country: store.country,
          });
        }
      });
      return regionalManagements;
    };

    const getRegions = (masterDataResponse: Store[]): Region[] => {
      const regions: Region[] = [];
      masterDataResponse.forEach(store => {
        if (regions.findIndex(r => r.id === store.regionId) === -1 && store.regionId !== null) {
          regions.push({
            label: store.region,
            id: store.regionId,
            divisionId: store.divisionId,
            country: store.country,
            regionalMgmtId: store.regionalMgmtId,
          });
        }
      });
      return regions;
    };

    const getManagements = (masterDataResponse: Store[]): Management[] => {
      const managements: Management[] = [];
      masterDataResponse.forEach(store => {
        if (managements.findIndex(m => m.id === store.mgmtId) === -1 && store.mgmtId !== null) {
          managements.push({
            label: store.mgmt,
            id: store.mgmtId,
            divisionId: store.divisionId,
            country: store.country,
            regionalMgmtId: store.regionalMgmtId,
            regionId: store.regionId,
          });
        }
      });
      return managements;
    };

    const getSupervisions = (masterDataResponse: Store[]): Supervision[] => {
      const supervisions: Supervision[] = [];
      masterDataResponse.forEach(store => {
        if (
          supervisions.findIndex(s => s.id === store.supervisionId) === -1 &&
          store.supervisionId !== null
        ) {
          supervisions.push({
            label: store.supervision,
            id: store.supervisionId,
            divisionId: store.divisionId,
            country: store.country,
            regionalMgmtId: store.regionalMgmtId,
            regionId: store.regionId,
            mgmtId: store.mgmtId,
          });
        }
      });
      return supervisions;
    };

    const getFranchises = (masterDataResponse: Store[]): Franchise[] => {
      const franchises: Franchise[] = [];
      masterDataResponse.forEach(store => {
        if (
          franchises.findIndex(f => f.id === store.companyId && f.country === store.country) ===
            -1 &&
          store.companyId !== null
        ) {
          franchises.push({
            id: store.companyId,
            label: store.companyName,
            divisionId: store.divisionId,
            country: store.country,
            agreementId: store.agreementId,
            agreementType: store.agreementType,
          });
        }
      });
      return franchises;
    };

    const getCountries = (masterDataResponse: Store[]): Country[] => {
      const countries: Country[] = [];
      masterDataResponse.forEach(store => {
        if (countries.findIndex(country => country.label === store.country) === -1) {
          countries.push({
            division: store.division,
            divisionId: store.divisionId,
            id: store.countryId,
            label: store.country,
          });
        }
      });
      return countries;
    };

    const getDivisions = (masterDataResponse: Store[]) => {
      const divisions: Set<string> = new Set();
      masterDataResponse.forEach(s => divisions.add(s.divisionId));
      return [...divisions];
    };

    const init = async () => {
      try {
        setLoading(true);
        if (!masterData) {
          const masterDataRegister = sessionStorage.getItem('masterOperativeData');
          if (masterDataRegister !== null) {
            const savedMasterData: MasterData = JSON.parse(masterDataRegister);
            setMasterData(savedMasterData);
            setFilteredDivisions(savedMasterData.divisions);
          } else {
            const masterDataResponse: Store[] = (await fetchOperativeStructure()).data;
            const divisions = getDivisions(masterDataResponse);
            setFilteredDivisions(divisions);
            const countries = getCountries(masterDataResponse);
            const regionalManagements = getRegionalManagements(masterDataResponse);
            const regions: Region[] = getRegions(masterDataResponse);
            const managements: Management[] = getManagements(masterDataResponse);
            const supervisions: Supervision[] = getSupervisions(masterDataResponse);
            const franchises: Franchise[] = getFranchises(masterDataResponse);
            const stores = masterDataResponse.sort(compareStores);
            setMasterData({
              divisions,
              countries,
              regionalManagements,
              regions,
              managements,
              supervisions,
              stores,
              franchises,
            });
            sessionStorage.setItem(
              'masterOperativeData',
              JSON.stringify({
                divisions,
                countries,
                regionalManagements,
                regions,
                managements,
                supervisions,
                stores,
                franchises,
              })
            );
          }
        }
      } catch (error) {
        showError(error);
      } finally {
        setLoading(false);
      }
    };

    init();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterByDivision();
    // eslint-disable-next-line
  }, [division, masterData]);

  useEffect(
    () => {
      if (countries.length > 0) filterByCountry();
      else filterByDivision();
    },
    // eslint-disable-next-line
    [countries, masterData]
  );

  useEffect(() => {
    if (regionalManagements.length > 0) {
      filterByRegionalManagement();
    } else if (countries.length > 0) {
      filterByCountry();
    } else filterByDivision();
    // eslint-disable-next-line
  }, [masterData, regionalManagements]);

  useEffect(() => {
    if (regions.length > 0) {
      filterByRegion();
    } else if (regionalManagements.length > 0) {
      filterByRegionalManagement();
    } else if (countries.length > 0) {
      filterByCountry();
    } else filterByDivision();
    // eslint-disable-next-line
  }, [masterData, regions]);

  useEffect(() => {
    if (managements.length > 0) {
      filterByManagement();
    } else if (regions.length > 0) {
      filterByRegion();
    } else if (regionalManagements.length > 0) {
      filterByRegionalManagement();
    } else if (countries.length > 0) {
      filterByCountry();
    } else filterByDivision();
    // eslint-disable-next-line
  }, [masterData, managements]);

  useEffect(() => {
    if (supervisions.length > 0) {
      filterBySupervision();
    } else if (managements.length > 0) {
      filterByManagement();
    } else if (regions.length > 0) {
      filterByRegion();
    } else if (regionalManagements.length > 0) {
      filterByRegionalManagement();
    } else if (countries.length > 0) {
      filterByCountry();
    } else filterByDivision();
    // eslint-disable-next-line
  }, [masterData, supervisions]);

  useEffect(() => {
    if (franchises.length > 0) {
      filterByFranchise();
    } else if (storeAgreements.length > 0) {
      filterByAgreement();
    } else if (supervisions.length > 0) {
      filterBySupervision();
    } else if (managements.length > 0) {
      filterByManagement();
    } else if (regions.length > 0) {
      filterByRegion();
    } else if (regionalManagements.length > 0) {
      filterByRegionalManagement();
    } else if (countries.length > 0) {
      filterByCountry();
      setFilteredAgreements(STORE_AGREEMENTS);
    } else {
      filterByDivision();
      setFilteredAgreements(STORE_AGREEMENTS);
    }
    // eslint-disable-next-line
  }, [masterData, franchises]);

  useEffect(() => {
    if (storeAgreements.length > 0) {
      filterByAgreement();
    } else if (countries.length > 0) {
      filterByCountry();
    } else filterByDivision();
    // eslint-disable-next-line
  }, [masterData, storeAgreements]);

  /**
   * Autocomplete Filters
   */

  useEffect(() => {
    if (filteredDivisions.length === 1) {
      updateFilters({ ...filters, division: [...filteredDivisions] });
    }
    // eslint-disable-next-line
  }, [filteredDivisions]);

  useEffect(() => {
    if (filters.division.length > 0 && filteredCountries.length === 1) {
      updateFilters({ ...filters, countries: [...filteredCountries] });
    }
    // eslint-disable-next-line
  }, [filteredCountries]);

  useEffect(() => {
    if (
      filters.division.length > 0 &&
      filters.countries.length > 0 &&
      filteredStores.length === 1
    ) {
      updateFilters({ ...filters, stores: [...filteredStores] });
    }
    // eslint-disable-next-line
  }, [filteredStores]);

  return (
    <InstitutionalContext.Provider
      value={{
        filteredDivisions,
        filteredCountries,
        filteredRegionalManagements,
        filteredRegions,
        filteredManagements,
        filteredSupervisions,
        filteredStores,
        filteredFranchises,
        filteredAgreements,
        loading,
      }}
    >
      {children}
    </InstitutionalContext.Provider>
  );
};

export const useInstitutional = () => useContext(InstitutionalContext);
