/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useState, useContext, useEffect } from 'react';
import { Report, FrontCounter, ChartData, AnnualReport, LabelLanguage } from '../types';
import { useAlert } from './alert';
import { useReport } from './report';
import {
  compareByR2PTime,
  formattedTimeIntervals,
  getExclusionRecordsLimitError,
  sortedAnnualByIntervalName,
  sortedByIntervalName,
} from '../utils/functions';
import { useApi } from './api';
import { useTranslation } from 'react-i18next';
import en from '../static/locales/en.json';
import es from '../static/locales/es.json';
import fr from '../static/locales/fr.json';
import pt from '../static/locales/pt.json';
import { AxiosError } from 'axios';

interface FrontCounterContext {
  frontCounter?: Report<FrontCounter>;
  annualReport?: Report<AnnualReport>;
  loading: boolean;
  timeline: ChartData;
  topFiveStores: ChartData;
  bottomFiveStores: ChartData;
  topFiveManagements: ChartData;
  ranking: FrontCounter[];
  dailyAverage: ChartData;
  getChartsData: () => Promise<void>;
  getFrontCounter: (inExclusions?: boolean) => Promise<void>;
  getRankingsData: () => Promise<void>;
  getAnnualReport: () => Promise<void>;
  requestExport: (type: 'detailed' | 'annual') => Promise<void>;
}

const initialState: FrontCounterContext = {
  frontCounter: undefined,
  annualReport: undefined,
  loading: false,
  timeline: { series: [], categories: [] },
  dailyAverage: { series: [], categories: [] },
  topFiveManagements: { series: [], categories: [] },
  topFiveStores: { series: [], categories: [] },
  bottomFiveStores: { series: [], categories: [] },
  ranking: [],
  getFrontCounter: () => new Promise<void>(() => {}),
  getChartsData: () => new Promise<void>(() => {}),
  getRankingsData: () => new Promise<void>(() => {}),
  getAnnualReport: () => new Promise<void>(() => {}),
  requestExport: () => new Promise(() => {}),
};

const FrontCounterContext = createContext(initialState);

interface Provider {
  children: ReactNode;
}

export const FrontCounterProvider = ({ children }: Provider) => {
  const { t, i18n } = useTranslation();
  const [frontCounter, setFrontCounter] = useState<Report<FrontCounter>>();
  const [annualReport, setAnnualReport] = useState<Report<AnnualReport>>();
  const [loading, setLoading] = useState(false);
  const [timeline, setTimeline] = useState<ChartData>(initialState.timeline);
  const [dailyAverage, setDailyAverage] = useState<ChartData>(initialState.dailyAverage);
  const [topFiveStores, setTopFiveStores] = useState<ChartData>(initialState.topFiveStores);
  const [topFiveManagements, setTopFiveManagements] = useState<ChartData>(
    initialState.topFiveManagements
  );
  const [bottomFiveStores, setBottomFiveStores] = useState<ChartData>(
    initialState.bottomFiveStores
  );
  const [ranking, setRanking] = useState<FrontCounter[]>([]);
  const { showError, showAlert } = useAlert();
  const { fetchFrontCounter, fetchFrontCounterRanking, fetchFcAnnualReport } = useApi();
  const {
    getFormattedFilters,
    getFilterLabels,
    newFiltersApplied,
    setNewFiltersApplied,
    filters,
  } = useReport();

  useEffect(() => {
    if (newFiltersApplied) {
      setFrontCounter(undefined);
      setAnnualReport(undefined);
      setTimeline({ series: [], categories: [] });
      setDailyAverage({ series: [], categories: [] });
      setTopFiveManagements({ series: [], categories: [] });
      setTopFiveStores({ series: [], categories: [] });
      setBottomFiveStores({ series: [], categories: [] });
      setRanking([]);
    }
  }, [newFiltersApplied]);

  const timelineTransformedModel = (data: FrontCounter[]) => {
    const timeline: ChartData = {
      series: [],
      categories: [],
    };
    const categories: string[] = [];
    const intervalNames: string[] = [];
    const hashmap: { [key: string]: { [key: string]: number } } = {};
    data.forEach(item => {
      if (item.businessDate && item.intervalName) {
        if (!categories.includes(item.businessDate)) categories.push(item.businessDate);
        if (!intervalNames.includes(item.intervalName)) intervalNames.push(item.intervalName);
        if (!hashmap[item.businessDate]) hashmap[item.businessDate] = {};
        hashmap[item.businessDate][item.intervalName] = item.r2p || 0;
      }
    });
    categories.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
    categories.forEach(key => {
      timeline.categories.push(key);
      const intervals = hashmap[key];
      let remainingIntervals = [...intervalNames];
      Object.keys(intervals).forEach(key => {
        remainingIntervals = remainingIntervals.filter(e => e !== key);
        const serieIndex = timeline.series.findIndex(s => s.name === formattedTimeIntervals[key]);
        if (serieIndex > -1) {
          timeline.series[serieIndex].data.push(intervals[key]);
        } else {
          timeline.series.push({ name: formattedTimeIntervals[key], data: [intervals[key]] });
        }
      });
      remainingIntervals.forEach(interval => {
        const serieIndex = timeline.series.findIndex(
          s => s.name === formattedTimeIntervals[interval]
        );
        if (serieIndex > -1) {
          timeline.series[serieIndex].data.push(0);
        } else {
          timeline.series.push({ name: formattedTimeIntervals[interval], data: [0] });
        }
      });
    });
    const sortOrder = ['Breakfast', 'Lunch', 'Snack', 'Dinner', 'Extended Hrs'];
    timeline.series.sort((a, b) => {
      if (sortOrder.indexOf(a.name) < sortOrder.indexOf(b.name)) return -1;
      if (sortOrder.indexOf(a.name) > sortOrder.indexOf(b.name)) return 1;
      return 0;
    });
    return timeline;
  };

  const dailyAverageTransformedModel = (data: FrontCounter[]) => {
    const dailyAverage: ChartData = {
      series: [{ name: '', data: [] }],
      categories: [],
    };
    data.forEach(item => {
      if (!dailyAverage.categories.includes(item.businessDate!)) {
        dailyAverage.categories.push(item.businessDate!);
        dailyAverage.series[0].data.push(item.r2p);
      }
    });
    return dailyAverage;
  };

  const getTopFiveManagements = (data: FrontCounter[]) => {
    const topFiveManagementsRaw = data.sort(compareByR2PTime).slice(0, 5);
    const topFiveManagements: ChartData = {
      series: [{ name: 'R2P', data: [] }],
      categories: [],
    };
    topFiveManagementsRaw.forEach(item => {
      if (!topFiveManagements.categories.includes(item['mgmt'] as string)) {
        topFiveManagements.categories.push(item['mgmt'] as string);
        topFiveManagements.series[0].data.push(item.r2p);
      }
    });
    return topFiveManagements;
  };

  const getTopFiveStores = (data: FrontCounter[]) => {
    const topFiveRaw = data.slice(0, 5);
    const topFive: ChartData = {
      series: [{ name: 'R2P', data: [] }],
      categories: [],
    };
    topFiveRaw.forEach(item => {
      if (!topFive.categories.includes(item.store!)) {
        topFive.categories.push(item.store!);
        topFive.series[0].data.push(item.r2p);
      }
    });
    return topFive;
  };

  const getBottomFiveStores = (data: FrontCounter[]) => {
    const bottomFiveRaw = data.slice(data.length - 5, data.length);
    const bottomFive: ChartData = {
      series: [{ name: 'R2P', data: [] }],
      categories: [],
    };
    bottomFiveRaw.forEach(item => {
      if (!bottomFive.categories.includes(item.store!)) {
        bottomFive.categories.push(item.store!);
        bottomFive.series[0].data.push(item.r2p);
      }
    });
    return bottomFive;
  };

  const getChartsData = async () => {
    try {
      setTimeline({ categories: [], series: [] });
      setDailyAverage({ categories: [], series: [] });
      setLoading(true);
      const timelineRequest = fetchFrontCounter({
        ...getFormattedFilters(),
        groupBy: 'businessDate,timeInterval',
      });
      const dailyAverageRequest = fetchFrontCounter({
        ...getFormattedFilters(),
        groupBy: 'businessDate',
      });
      const [timelineResponse, dailyAverageResponse] = await Promise.all([
        timelineRequest,
        dailyAverageRequest,
      ]);
      setTimeline(timelineTransformedModel(timelineResponse.data.results));
      setDailyAverage(dailyAverageTransformedModel(dailyAverageResponse.data.results));
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const getRankingsData = async () => {
    try {
      setLoading(true);
      const managementsRequest = fetchFrontCounterRanking({
        ...getFormattedFilters(),
        groupBy: 'mgmt',
      });
      const storesRequest = fetchFrontCounterRanking({
        ...getFormattedFilters(),
        groupBy: 'store',
      });
      const [managementsResponse, storesResponse] = await Promise.all([
        managementsRequest,
        storesRequest,
      ]);
      const sortedStores = storesResponse.data.results.sort(compareByR2PTime);
      setTopFiveStores(getTopFiveStores(sortedStores));
      setBottomFiveStores(getBottomFiveStores(sortedStores));
      setTopFiveManagements(getTopFiveManagements(managementsResponse.data.results));
      const sortedFrontCounter = (
        await fetchFrontCounterRanking({
          ...getFormattedFilters(),
          groupBy: `store,${filters.rankingField}`,
        })
      ).data.results.sort(compareByR2PTime);
      setRanking(sortedFrontCounter);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const getTotalStores = (results: FrontCounter[] | AnnualReport[]) => {
    let count = 0;
    let stores: { [key: string]: number } = {};
    results.forEach((e: FrontCounter | AnnualReport) => {
      if (e.store && !stores[e.store]) {
        stores[e.store] = 1;
        count++;
      }
    });
    return `#${count}`;
  };

  const getFrontCounter = async (inExclusions?: boolean) => {
    try {
      setFrontCounter(undefined);
      setLoading(true);
      const response = (await fetchFrontCounter(getFormattedFilters(undefined, inExclusions))).data;
      setFrontCounter({
        totals: { ...response['result_totals'][0], store: getTotalStores(response.results) },
        data: sortedByIntervalName(response.results) as FrontCounter[],
      });
    } catch (error) {
      const customError = inExclusions
        ? getExclusionRecordsLimitError(error as AxiosError)
        : undefined;
      showError(error, customError);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const getAnnualReport = async () => {
    try {
      setAnnualReport(undefined);
      setLoading(true);
      const response = (await fetchFcAnnualReport(getFormattedFilters(true))).data;
      setAnnualReport({
        totals: { ...response['result_totals'][0], store: getTotalStores(response.results) },
        data: sortedAnnualByIntervalName(response.results),
      });
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const requestExport = async (type: 'detailed' | 'annual') => {
    try {
      setLoading(true);
      let labels: LabelLanguage = {};
      switch (i18n.language) {
        case 'en':
          labels = { ...en.reports.labels };
          break;
        case 'es':
          labels = { ...es.reports.labels };
          break;
        case 'fr':
          labels = { ...fr.reports.labels };
          break;
        case 'pt':
          labels = { ...pt.reports.labels };
          break;
        default:
          break;
      }
      showAlert(t('dialogs.exportRequested.title'), t('dialogs.exportRequested.body'));
      if (type === 'detailed')
        await fetchFrontCounter(getFormattedFilters(), true, labels, getFilterLabels());
      if (type === 'annual')
        await fetchFcAnnualReport(getFormattedFilters(true), true, labels, getFilterLabels(true));
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FrontCounterContext.Provider
      value={{
        frontCounter,
        annualReport,
        timeline,
        dailyAverage,
        topFiveManagements,
        topFiveStores,
        bottomFiveStores,
        ranking,
        getRankingsData,
        getChartsData,
        getFrontCounter,
        getAnnualReport,
        requestExport,
        loading,
      }}
    >
      {children}
    </FrontCounterContext.Provider>
  );
};

export const useFrontCounter = () => useContext(FrontCounterContext);
