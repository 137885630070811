import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useReport } from '../../../context/report';
import { useTranslation } from 'react-i18next';
import { useInstitutional } from '../../../context/institutional';
import { ReportFrequency } from '../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 90,
    },
  })
);

interface FrequencySelector {
  noLabel?: boolean;
}

const FrequencySelector = ({ noLabel }: FrequencySelector) => {
  const { frequency, updateFrequency } = useReport();
  const { loading: loadingInstitutionalData } = useInstitutional();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleSelectFrequency = (event: React.ChangeEvent<{ value: unknown }>) => {
    updateFrequency(event.target.value as ReportFrequency);
  };

  return (
    <FormControl className={classes.formControl}>
      {!noLabel && (
        <InputLabel id="freq-simple-select-label">
          {t('reports.labels.frequency')}
        </InputLabel>
      )}
      <Select
        labelId="freq-simple-select-label"
        id="freq-simple-select"
        value={frequency}
        onChange={handleSelectFrequency}
        required
        disabled={loadingInstitutionalData}
      >
        <MenuItem value="beginTime">{t('reports.labels.quarterly')}</MenuItem>
        <MenuItem value="hour">{t('reports.labels.hourly')}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default FrequencySelector;
