import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReportType } from '../../../types';
import { useReport } from '../../../context/report';
import { useHistory } from 'react-router-dom';

const ReportSelector = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { reportType, updateReportType } = useReport();

  const handleChange = (value: ReportType) => {
    updateReportType(value);
    history.push(`${value}`);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="select-label">{t('shared.labels.reportType')}</InputLabel>
      <Select
        labelId="select-label"
        id="reportType"
        value={reportType}
        label="reportType"
        onChange={event => handleChange(event.target.value as ReportType)}
      >
        <MenuItem value={'detailed'}>{t('shared.labels.detailed')}</MenuItem>
        <MenuItem value={'annual'}>{t('shared.labels.annual')}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ReportSelector;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
  })
);
