import {
  Column,
  Store,
  DriveThru,
  ChartData,
  MadeForYou,
  FrontCounter,
  OperativeField,
  AnnualReport,
  SOSExclusions,
} from '../types';
import { format, isBefore, Locale, parse } from 'date-fns';
import { es, enUS, pt, fr } from 'date-fns/esm/locale';
import { MGMT_PREFIX, REGION_PREFIX, codedErrors } from './constants';
import { AxiosError } from 'axios';

export const parseTime = (time: string) => {
  return time.slice(0, 2) + ':' + time.slice(2, 4);
};

export const compareGroupingOptions = <T>(a: Column<T>, b: Column<T>): number => {
  const options: { [key: string]: number } = {
    division: 0,
    country: 1,
    regionalManagement: 2,
    region: 3,
    management: 4,
    supervision: 5,
    store: 6,
    time: 7,
  };
  return options[a.field] - options[b.field];
};

const locales: { [key: string]: Locale } = {
  es,
  en: enUS,
  pt,
  fr,
};

export const formatISOdate = (date: string, language: string): string => {
  return format(parse(date, 'yyyy-MM-d', new Date()), 'P', {
    locale: locales[language.split('-')[0]],
  });
};

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export const compareStores = (a: Store, b: Store): number => {
  if (a.storeAcronym < b.storeAcronym) return -1;
  if (a.storeAcronym > b.storeAcronym) return 1;
  return 0;
};

// export const formattedTimeIntervals: { [key: string]: string } = {
//   dawn: '01:00 - 06:59', // Extended Hrs   *ver si se puede poner a lo ultimo
//   morning: '07:00 - 10:59', // Breakfast
//   noon: '11:00 - 14:59', // Lunch
//   afternoon: '15:00 - 19:59', // Snack
//   night: '20:00 - 00:59', // Dinner
// };

export const formattedTimeIntervals: { [key: string]: string } = {
  extended_hrs: 'Extended Hrs',
  breakfast: 'Breakfast',
  lunch: 'Lunch',
  snack: 'Snack',
  dinner: 'Dinner',
};

export const timelineTransformedModel = (data: DriveThru[]) => {
  const timeline: ChartData = {
    series: [],
    categories: [],
  };
  const categories: string[] = [];
  const intervalNames: string[] = [];
  const hashmap: { [key: string]: { [key: string]: number } } = {};
  data.forEach(item => {
    if (item.businessDate && item.intervalName) {
      if (!categories.includes(item.businessDate)) categories.push(item.businessDate);
      if (!intervalNames.includes(item.intervalName)) intervalNames.push(item.intervalName);
      if (!hashmap[item.businessDate]) hashmap[item.businessDate] = {};
      hashmap[item.businessDate][item.intervalName] = item.oepe || 0;
    }
  });
  categories.sort((a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  });
  categories.forEach(key => {
    timeline.categories.push(key);
    const intervals = hashmap[key];
    let remainingIntervals = [...intervalNames];
    Object.keys(intervals).forEach(key => {
      remainingIntervals = remainingIntervals.filter(e => e !== key);
      const serieIndex = timeline.series.findIndex(s => s.name === formattedTimeIntervals[key]);
      if (serieIndex > -1) {
        timeline.series[serieIndex].data.push(intervals[key]);
      } else {
        timeline.series.push({ name: formattedTimeIntervals[key], data: [intervals[key]] });
      }
    });
    remainingIntervals.forEach(interval => {
      const serieIndex = timeline.series.findIndex(
        s => s.name === formattedTimeIntervals[interval]
      );
      if (serieIndex > -1) {
        timeline.series[serieIndex].data.push(0);
      } else {
        timeline.series.push({ name: formattedTimeIntervals[interval], data: [0] });
      }
    });
  });
  const sortOrder = ['Breakfast', 'Lunch', 'Snack', 'Dinner', 'Extended Hrs'];
  timeline.series.sort((a, b) => {
    if (sortOrder.indexOf(a.name) < sortOrder.indexOf(b.name)) return -1;
    if (sortOrder.indexOf(a.name) > sortOrder.indexOf(b.name)) return 1;
    return 0;
  });
  return timeline;
};

export const dailyAverageTransformedModel = (data: DriveThru[]) => {
  const dailyAverage: ChartData = {
    series: [{ name: '', data: [] }],
    categories: [],
  };
  data.forEach(item => {
    if (!dailyAverage.categories.includes(item.businessDate!)) {
      dailyAverage.categories.push(item.businessDate!);
      dailyAverage.series[0].data.push(item.oepe);
    }
  });
  return dailyAverage;
};

export const formatNumberOfSeconds = (numberOfSeconds: number, inTime?: boolean): string => {
  numberOfSeconds = numberOfSeconds === undefined ? 0 : numberOfSeconds;
  if (inTime) {
    const hours = Math.floor(numberOfSeconds / 3600);
    const minutes = Math.floor((numberOfSeconds - hours * 3600) / 60);
    const seconds = numberOfSeconds - hours * 3600 - minutes * 60;
    return `${minutes < 10 ? 0 : ''}${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
  }
  return `${numberOfSeconds}`;
};

export const compareByOepeTime = (a: DriveThru, b: DriveThru) => {
  if (a.oepe > b.oepe) return 1;
  if (a.oepe < b.oepe) return -1;
  return 0;
};

export const compareByOrderTime = (a: MadeForYou, b: MadeForYou) => {
  if (a.tts > b.tts) return 1;
  if (a.tts < b.tts) return -1;
  return 0;
};

export const compareByR2PTime = (a: FrontCounter, b: FrontCounter) => {
  if (a.r2p > b.r2p) return 1;
  if (a.r2p < b.r2p) return -1;
  return 0;
};

export const getTopFiveManagements = (data: DriveThru[], operativeField: OperativeField) => {
  const topFiveManagementsRaw = data.sort(compareByOepeTime).slice(0, 5);
  const topFiveManagements: ChartData = {
    series: [{ name: 'OEPE', data: [] }],
    categories: [],
  };
  topFiveManagementsRaw.forEach(item => {
    if (!topFiveManagements.categories.includes(item[operativeField] as string)) {
      topFiveManagements.categories.push(item[operativeField] as string);
      topFiveManagements.series[0].data.push(item.oepe);
    }
  });
  return topFiveManagements;
};

export const getTopFiveStores = (data: DriveThru[]) => {
  const topFiveRaw = data.slice(0, 5);
  const topFive: ChartData = {
    series: [{ name: 'OEPE', data: [] }],
    categories: [],
  };
  topFiveRaw.forEach(item => {
    if (!topFive.categories.includes(item.store!)) {
      topFive.categories.push(item.store!);
      topFive.series[0].data.push(item.oepe);
    }
  });
  return topFive;
};

export const getBottomFiveStores = (data: DriveThru[]) => {
  const bottomFiveRaw = data.slice(data.length - 5, data.length);
  const bottomFive: ChartData = {
    series: [{ name: 'OEPE', data: [] }],
    categories: [],
  };
  bottomFiveRaw.forEach(item => {
    if (!bottomFive.categories.includes(item.store!)) {
      bottomFive.categories.push(item.store!);
      bottomFive.series[0].data.push(item.oepe);
    }
  });
  return bottomFive;
};

export function isValidDate(date: Date) {
  return !isNaN(date.getDate());
}

export function sortedByIntervalName(
  data: DriveThru[] | MadeForYou[] | FrontCounter[] | SOSExclusions[]
) {
  const sortOrder = ['breakfast', 'lunch', 'snack', 'dinner', 'extended_hrs'];
  const sortedData = data.sort(
    (
      a: DriveThru | MadeForYou | FrontCounter | SOSExclusions,
      b: DriveThru | MadeForYou | FrontCounter | SOSExclusions
    ) => {
      if (a.intervalName && b.intervalName) {
        if (sortOrder.indexOf(a.intervalName) < sortOrder.indexOf(b.intervalName)) return -1;
        if (sortOrder.indexOf(a.intervalName) > sortOrder.indexOf(b.intervalName)) return 1;
      }
      return 0;
    }
  );
  return sortedData;
}

export function sortedAnnualByIntervalName(data: AnnualReport[]) {
  const sortOrder = ['breakfast', 'lunch', 'snack', 'dinner', 'extended_hrs'];
  const sortedData = data.sort((a: AnnualReport, b: AnnualReport) => {
    if (a.intervalName && b.intervalName) {
      if (sortOrder.indexOf(a.intervalName) < sortOrder.indexOf(b.intervalName)) return -1;
      if (sortOrder.indexOf(a.intervalName) > sortOrder.indexOf(b.intervalName)) return 1;
    }
    return 0;
  });
  return sortedData;
}

export function sortedByLastUpdate(data: SOSExclusions[]) {
  const sortedData = data.sort((a: SOSExclusions, b: SOSExclusions) => {
    if (a.lastUpdate && b.lastUpdate) {
      if (isBefore(new Date(a.lastUpdate), new Date(b.lastUpdate))) return 1;
      if (isBefore(new Date(b.lastUpdate), new Date(a.lastUpdate))) return -1;
    }
    return 0;
  });
  return sortedData;
}

export function regionNameFormatter(key: string) {
  if (REGION_PREFIX[key]) return REGION_PREFIX[key];
  return key;
}

export function mgmtNameFormatter(key: string) {
  if (MGMT_PREFIX[key]) return MGMT_PREFIX[key];
  return key;
}

export function getExclusionRecordsLimitError(error: AxiosError) {
  const code = error.response?.data;
  if (code === codedErrors[0]) return codedErrors[1];
}
