import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Theme,
  Toolbar,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useReport } from '../../../context/report';
import { useAuthentication } from '../../../context/authentication';
import { SOSExclusions } from '../../../types';
import AutoSelect from '../AutoSelect';
import DateRangePicker from '../DateRangePicker';
import SegmentSelector from '../SegmentSelector';
import CountrySelector from '../selectors/CountrySelector';
import DivisionSelector from '../selectors/DivisionSelector';
import ServiceSelector from '../selectors/ServiceSelector';
import { green } from '@material-ui/core/colors';
import { isSameDay } from 'date-fns';
import DisableToDateCheckbox from '../selectors/DisableToDateCheckbox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: { whiteSpace: 'break-spaces' },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    titleContainter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginRight: theme.spacing(2),
    },
    subtitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    resetButton: {
      margin: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    loadingWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
  })
);

interface Props {
  open: boolean;
  loading: boolean;
  data?: SOSExclusions;
  handleClose: () => void;
}

const ExclusionsFormDialog = ({ open, loading, data, handleClose }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    filters,
    createExclusionsReport,
    updateExclusionsReport,
    deleteExclusionsReport,
  } = useReport();
  const { account } = useAuthentication();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const initialFormData: Partial<SOSExclusions> = {
    reason: '',
    ticket: '',
    userUpdate: account?.username || '',
  };
  const [formData, setFormData] = React.useState<Partial<SOSExclusions>>(initialFormData);
  const [charLimit, setCharLimit] = React.useState(200);
  const [dateError, setDateError] = React.useState(false);
  const isEdit = Boolean(data);
  const missingData =
    !isEdit &&
    (formData.reason === '' ||
      formData.ticket === '' ||
      filters.countries.length === 0 ||
      filters.stores.length === 0);
  const disableSubmit = loading || missingData || (!filters.disableToDate && dateError);
  const inSameDay = isSameDay(filters.fromDate, filters.toDate);

  useEffect(() => {
    if (data) setFormData({ ...data });
    else setFormData(initialFormData);
    setCharLimit(200);
    // eslint-disable-next-line
  }, [open, data]);

  const handleTicket = (value: string) => {
    const regexp = /[^0-9]/g;
    if (!regexp.test(value)) {
      const newFormData = { ...formData };
      newFormData['ticket'] = value;
      setFormData(newFormData);
    }
  };

  const handleReason = (value: string) => {
    const chars = value.length;
    if (chars <= 200) {
      const newFormData = { ...formData };
      newFormData['reason'] = value;
      setFormData(newFormData);
      setCharLimit(200 - chars);
    }
  };

  const handleCloseDialog = () => {
    handleClose();
    setFormData(initialFormData);
  };

  const handleSubmit = async () => {
    const newData: Partial<SOSExclusions> = { ...formData, ticket: `SD-${formData.ticket}` };
    if (isEdit) await updateExclusionsReport(newData);
    else await createExclusionsReport(newData);
    handleCloseDialog();
  };

  const handleDelete = async () => {
    const userUpdate = data?.userUpdate || '';
    const id = `${formData.storePK}_${formData.fromDate}`;
    await deleteExclusionsReport(userUpdate, id);
    handleCloseDialog();
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth fullScreen={isMobile}>
      {isMobile && (
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {t('dialogs.exclusions.title')}
            </Typography>
            {isEdit && (
              <div className={classes.loadingWrapper}>
                <Button color="secondary" onClick={handleDelete} disabled={loading}>
                  {t('navigation.buttons.delete')}
                </Button>
                {loading && <CircularProgress size={22} className={classes.buttonProgress} />}
              </div>
            )}
          </Toolbar>
        </AppBar>
      )}
      {!isMobile && (
        <Box className={classes.titleContainter}>
          <DialogTitle>{t('dialogs.exclusions.title')}</DialogTitle>
          {isEdit && (
            <div className={classes.loadingWrapper}>
              <Button color="secondary" onClick={handleDelete} disabled={loading}>
                {t('navigation.buttons.delete')}
              </Button>
              {loading && <CircularProgress size={22} className={classes.buttonProgress} />}
            </div>
          )}
        </Box>
      )}
      <DialogContent>
        <Grid container item direction="row">
          <ServiceSelector value={isEdit ? formData.service : undefined} disabled={isEdit} />
          <DivisionSelector value={isEdit ? formData.division : undefined} disabled={isEdit} />
          <CountrySelector value={isEdit ? formData.country : undefined} disabled={isEdit} />
          <AutoSelect
            singleValue
            storeAcronym={isEdit ? formData.storeAcronym : undefined}
            disabled={isEdit}
          />
        </Grid>
        <Grid container item direction="row" style={{ marginTop: 20 }}>
          <DateRangePicker error={dateError} setError={setDateError} disableFrom={isEdit} />
          {inSameDay && !filters.disableToDate && <SegmentSelector />}
          <DisableToDateCheckbox />
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 30 }}>
          <Grid item xs={12}>
            <DialogContentText className={classes.body}>
              {t('dialogs.exclusions.body')}
            </DialogContentText>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              required
              fullWidth
              id="ticket"
              size="small"
              label={t('dialogs.exclusions.ticket')}
              variant="outlined"
              value={formData.ticket || ''}
              onChange={e => handleTicket(e.target.value)}
              disabled={isEdit}
              InputProps={{
                startAdornment: !isEdit && (
                  <InputAdornment position="start" style={{ opacity: 0.8 }}>
                    {'SD-'}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} lg={8}>
            <TextField
              required
              fullWidth
              id="ticket"
              size="small"
              label={t('dialogs.exclusions.userUpdate')}
              variant="outlined"
              value={formData.userUpdate || ''}
              disabled={true}
              style={{ width: '' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="reason"
              label={t('dialogs.exclusions.reason')}
              variant="outlined"
              multiline
              rows={3}
              value={formData.reason || ''}
              helperText={`${t('dialogs.exclusions.charLimit')} (${charLimit})`}
              focused={true}
              onChange={e => handleReason(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCloseDialog}>
          {t('navigation.buttons.cancel')}
        </Button>
        <div className={classes.loadingWrapper}>
          <Button color="primary" onClick={handleSubmit} disabled={disableSubmit}>
            {t('navigation.buttons.confirm')}
          </Button>
          {loading && <CircularProgress size={22} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ExclusionsFormDialog;
