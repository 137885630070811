/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

interface SimpleSelector {
  label?: string;
  options: string[];
  disabledOptions?: string[];
  value: string | string[];
  disabled?: boolean;
  error?: boolean;
  multiple?: boolean;
  caption?: string;
  fullWidth?: boolean;
  handleSelect: (newValue: string | string[]) => void;
  optionFormatter?: (option: string) => string;
}

const SimpleSelector = ({
  label,
  options,
  disabledOptions,
  value,
  multiple,
  disabled,
  error,
  caption,
  fullWidth,
  handleSelect,
  optionFormatter,
}: SimpleSelector) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderValue = (selected: unknown) => {
    if (optionFormatter) {
      if (multiple) return (selected as string[]).map(o => optionFormatter(o)).join();
      else return optionFormatter(selected as string);
    } else {
      if (multiple) return (selected as string[]).join();
      else return selected as string;
    }
  };

  return (
    <FormControl className={classes.formControl} fullWidth={fullWidth}>
      {label && <InputLabel id="division-simple-select-label">{label}</InputLabel>}
      <Select
        labelId="division-simple-select-label"
        id="division-simple-select"
        value={value}
        multiple={multiple}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          handleSelect(multiple ? (event.target.value as string[]) : (event.target.value as string))
        }
        required
        disabled={disabled}
        fullWidth={fullWidth}
        autoWidth
        error={error}
        renderValue={renderValue}
      >
        {options.includes('') && (
          <MenuItem value="">
            <em>{t('shared.labels.none')}</em>
          </MenuItem>
        )}
        {options
          .filter(o => o !== '')
          .map(option => (
            <MenuItem
              key={option}
              value={option}
              disabled={disabledOptions && disabledOptions.includes(option)}
            >
              <Checkbox checked={value.includes(option)} />
              {optionFormatter ? optionFormatter(option) : option}
            </MenuItem>
          ))}
      </Select>
      {error && <FormHelperText error={error}>{caption}</FormHelperText>}
    </FormControl>
  );
};

export default SimpleSelector;
