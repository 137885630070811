import React, { useMemo } from 'react';
import { render } from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Router from './Router';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { UserProvider } from './context/user';
import { useMediaQuery, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { AlertProvider } from './context/alert';
import { usePreferences, PreferencesProvider } from './context/preferences';
import { AuthenticationProvider } from './context/authentication';
import { ApiProvider } from './context/api';

const ThemedApp = () => {
  const { preferences } = usePreferences();
  const systemTheme = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';
  let prefersDarkMode: 'light' | 'dark' = 'light';
  switch (preferences.theme) {
    case 'dark':
      prefersDarkMode = 'dark';
      break;
    case 'light':
      prefersDarkMode = 'light';
      break;
    case 'system':
      prefersDarkMode = systemTheme;
  }
  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: '#009A61',
          },
          type: prefersDarkMode,
        },
      }),
    [prefersDarkMode]
  );

  return (
    <MuiThemeProvider theme={theme}>
      <AlertProvider>
        <AuthenticationProvider>
          <ApiProvider>
            <UserProvider>
              <Router />
            </UserProvider>
          </ApiProvider>
        </AuthenticationProvider>
      </AlertProvider>
    </MuiThemeProvider>
  );
};

const App = () => {
  return (
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <PreferencesProvider>
          <ThemedApp />
        </PreferencesProvider>
      </I18nextProvider>
    </React.StrictMode>
  );
};

const root = document.getElementById('root');
if (root !== null) {
  // axiosConfig();
  render(<App />, root);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
