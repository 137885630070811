import React from 'react';
import { makeStyles, Theme, createStyles, Fab, Hidden, Box } from '@material-ui/core';
import HideOnScroll from '../shared/HideOnScroll';
import ReportIcon from '@material-ui/icons/SwapVert';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useReport } from '../../context/report';

interface FloatingActionButton {
  title?: string;
  icon: JSX.Element;
  handleClick: () => void;
  handleCreateExclusion?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2.5),
      right: theme.spacing(2),
    },
  })
);

const FloatingActionButton = ({
  title,
  icon,
  handleClick,
  handleCreateExclusion,
}: FloatingActionButton) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { reportType, updateReportType } = useReport();
  const inReport = location.pathname.includes('report');
  const inExclusions = location.pathname.includes('exclusions');

  const handleReportType = () => {
    switch (reportType) {
      case 'detailed':
        updateReportType('annual');
        history.push('annual');
        break;
      case 'annual':
        updateReportType('detailed');
        history.push('detailed');
        break;
      default:
        break;
    }
  };

  return (
    <Hidden implementation="css" lgUp>
      <HideOnScroll>
        <Box className={classes.fab}>
          {inExclusions && (
            <Fab
              variant={title ? 'extended' : 'round'}
              size="medium"
              color="primary"
              aria-label="filter"
              onClick={() => handleCreateExclusion && handleCreateExclusion()}
              style={{ borderRadius: '5px 0px 0px 5px' }}
            >
              {`+ ${t('reports.labels.addExclusion')}`}
            </Fab>
          )}
          {inReport && (
            <Fab
              variant={title ? 'extended' : 'round'}
              size="medium"
              color="primary"
              aria-label="filter"
              onClick={handleReportType}
              style={{ borderRadius: '5px 0px 0px 5px' }}
            >
              <ReportIcon />
              {reportType === 'annual' ? t('shared.labels.annual') : t('shared.labels.detailed')}
            </Fab>
          )}
          <Fab
            variant={title ? 'extended' : 'round'}
            size="medium"
            color="primary"
            aria-label="filter"
            onClick={handleClick}
            style={inReport || inExclusions ? { borderRadius: '0px 5px 5px 0px' } : undefined}
          >
            {icon}
            {title}
          </Fab>
        </Box>
      </HideOnScroll>
    </Hidden>
  );
};

export default FloatingActionButton;
