import React, { FunctionComponent } from 'react';
import { useUser } from '../../context/user';
import { AppPermission } from '../../types';

const withRestriction = (Children: FunctionComponent, permissionRequested: AppPermission) => (
  props: any
) => {
  const { permissions } = useUser();
  if (permissions && permissions.includes(permissionRequested)) {
    return <Children {...props} />;
  } else return null;
};

export default withRestriction;
