import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  Checkbox,
  useTheme,
  useMediaQuery,
  List,
  ListSubheader,
} from '@material-ui/core';
import { Store } from '../../types';
import { useReport } from '../../context/report';
import { useInstitutional } from '../../context/institutional';
import { useTranslation } from 'react-i18next';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 130,
    },
  })
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const defaultStore: Store = {
  agreementId: null,
  agreementType: '',
  companyId: '',
  companyName: '',
  division: '',
  divisionId: '',
  country: '',
  countryId: '',
  regionalMgmt: '',
  regionalMgmtId: '',
  region: '',
  regionId: '',
  mgmtId: '',
  mgmt: '',
  supervisionId: '',
  supervision: '',
  storeId: '',
  storeAcronym: '',
};

interface Props {
  noLabel?: boolean;
  storeAcronym?: string;
  singleValue?: boolean;
  disabled?: boolean;
}

const AutoSelect = ({ noLabel, storeAcronym, disabled, singleValue }: Props) => {
  const { t } = useTranslation();
  const { filters, updateFilters } = useReport();
  const { loading: loadingInstitutional, filteredStores } = useInstitutional();
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const disableSelector =
    disabled ||
    loadingInstitutional ||
    filters.division.length === 0 ||
    (filteredStores.length < 2 && filters.stores.length > 0);

  const renderValue = (selected: Store[]) => {
    if (smDown) {
      if ((selected as Store[]).length > 1) {
        return `${(selected as Store[]).length} ${t('shared.labels.selected')}`;
      } else {
        return (selected as Store[])[0].storeAcronym;
      }
    } else {
      return (selected as Store[]).map(t => t.storeAcronym).join(', ') + ' ';
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    ignoreCase: true,
    // limit: 5,
    stringify: (option: Store) => option.storeAcronym,
  });

  const renderGroup = (element: AutocompleteRenderGroupParams) => (
    <List
      id="multiple-store-list"
      key={element.key}
      subheader={
        <ListSubheader
          style={{ backgroundColor: theme.palette.background.paper, zIndex: 2, top: -10 }}
        >
          {t(`shared.labels.${element.group}`)}
        </ListSubheader>
      }
    >
      {element.children}
    </List>
  );

  const handleChange = (value: Store[]) => {
    if (singleValue && value.length > 1) {
      const newValue = [value[value.length - 1]];
      updateFilters({ ...filters, stores: newValue as Store[] });
    } else {
      updateFilters({ ...filters, stores: value as Store[] });
    }
  };

  return (
    <Autocomplete
      multiple
      id="multiple-store-select"
      autoComplete
      includeInputInList
      value={storeAcronym ? [{ ...defaultStore, storeAcronym }] : filters.stores}
      filterOptions={filterOptions}
      options={filteredStores}
      groupBy={(filters.countries.length > 1 && (option => option.country)) || undefined}
      renderGroup={(filters.countries.length > 1 && renderGroup) || undefined}
      disableCloseOnSelect
      getOptionLabel={option => option.storeAcronym}
      noOptionsText={t('toolbar.labels.noOptionsText')}
      onChange={(event, newValue) => handleChange(newValue as Store[])}
      renderTags={tags => renderValue(tags)}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 3, marginLeft: 0, zIndex: 1 }}
            checked={selected}
            size="small"
          />
          {option.storeAcronym}
        </React.Fragment>
      )}
      renderInput={params => (
        <FormControl className={classes.formControl}>
          <TextField
            {...params}
            variant="standard"
            label={(!noLabel && t('reports.labels.stores')) || undefined}
          />
        </FormControl>
      )}
      disabled={disableSelector}
    />
  );
};

export default AutoSelect;
