import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import { useTranslation } from 'react-i18next';
import GetAppIcon from '@material-ui/icons/GetApp';
import { TableAction } from '.';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      borderRadius: '4px 4px 0px 0px',
    },
    title: {
      flex: '1 1 100%',
      color: theme.palette.common.white,
    },
    actionButton: {
      color: theme.palette.common.white,
    },
  })
);
interface Toolbar {
  title: string;
  handleDownloadExcel: () => void;
  isEmpty?: boolean;
  actions?: TableAction[];
}

const TableToolbar = ({ title, handleDownloadExcel, isEmpty, actions }: Toolbar) => {
  const classes = useToolbarStyles();
  const { t } = useTranslation();

  return (
    <Toolbar className={classes.root} color="primary">
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {title}
      </Typography>
      <Tooltip title={t('table.toolbar.downloadButton').toString()}>
        <span>
          <IconButton
            aria-label="download data in Excel"
            onClick={handleDownloadExcel}
            disabled={isEmpty}
            className={classes.actionButton}
          >
            <GetAppIcon />
          </IconButton>
        </span>
      </Tooltip>
      {actions &&
        actions.map((action, index) => (
          <Tooltip title={action.label} key={`action-${index}`}>
            <span>
              <IconButton
                aria-label={action.label}
                onClick={action.handleClick}
                className={classes.actionButton}
              >
                {action.icon}
              </IconButton>
            </span>
          </Tooltip>
        ))}
    </Toolbar>
  );
};

export default TableToolbar;
