import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  useMediaQuery,
  useTheme,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Tooltip,
  IconButton,
  withStyles,
  Badge,
  List,
  ListSubheader,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useInstitutional } from '../../../context/institutional';
import { useReport } from '../../../context/report';
import BusinessIcon from '@material-ui/icons/Business';
import { RegionalManagement, Region, Management, Supervision } from '../../../types';
import AutocompleteSelector from '../../selectors/autocomplete';
import { AutocompleteRenderGroupParams } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 160,
    },
    iconButton: {
      margin: theme.spacing(1),
    },
  })
);

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  })
)(Badge);

const OperativeFilters = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const {
    filteredRegionalManagements,
    filteredRegions,
    filteredManagements,
    filteredSupervisions,
    loading: loadingInstitutional,
  } = useInstitutional();
  const { filters, updateFilters } = useReport();
  const [openDialog, setOpenDialog] = useState(false);
  const { division, regions, managements, supervisions } = filters;
  const [numberOfOperativeFilters, setNumberOfOperativeFilters] = useState(0);

  useEffect(() => {
    let newNumber = 0;
    filters.regionalManagements.length > 0 && newNumber++;
    filters.regions.length > 0 && newNumber++;
    filters.managements.length > 0 && newNumber++;
    filters.supervisions.length > 0 && newNumber++;
    setNumberOfOperativeFilters(newNumber);
  }, [filters.regionalManagements, filters.regions, filters.managements, filters.supervisions]);

  const handleReset = () => {
    updateFilters({
      regionalManagements: [],
      regions: [],
      managements: [],
      supervisions: [],
    });
    setOpenDialog(false);
  };

  const handleApply = () => {
    setOpenDialog(false);
  };

  const handleSelectRegionalManagement = (selection: RegionalManagement[]) => {
    updateFilters({
      regionalManagements: selection,
      regions: regions.filter(r => selection.map(s => s.id).includes(r.regionalMgmtId)),
      managements: managements.filter(m => selection.map(s => s.id).includes(m.regionalMgmtId)),
      supervisions: supervisions.filter(s => selection.map(rm => rm.id).includes(s.regionalMgmtId)),
      stores: [],
    });
  };

  const handleSelectRegion = (selection: Region[]) => {
    updateFilters({
      regions: selection,
      managements: managements.filter(m => selection.map(s => s.id).includes(m.regionId)),
      supervisions: supervisions.filter(s => selection.map(r => r.id).includes(s.regionId)),
      stores: [],
    });
  };

  const handleSelectManagements = (selection: Management[]) => {
    updateFilters({
      managements: selection,
      supervisions: supervisions.filter(s => selection.map(m => m.id).includes(s.mgmtId)),
      stores: [],
    });
  };

  const handleSelectSupervisions = (selection: Supervision[]) => {
    updateFilters({
      supervisions: selection,
      stores: [],
    });
  };

  const renderCountryGroup = (element: AutocompleteRenderGroupParams) => (
    <List
      key={element.key}
      subheader={<ListSubheader>{t(`shared.labels.${element.group}`)}</ListSubheader>}
    >
      {element.children}
    </List>
  );

  return (
    <Grid item className={classes.iconButton}>
      <Tooltip
        title={t('toolbar.labels.operativeFilters').toString()}
        placement="bottom"
        id="operativeFilters-tooltip"
      >
        <span id="operativeFilters-span">
          <IconButton
            id="operativeFilters-icon-button"
            aria-label="extra-filters"
            onClick={() => setOpenDialog(true)}
            disabled={division.length === 0 || loadingInstitutional}
          >
            <StyledBadge badgeContent={numberOfOperativeFilters} color="secondary">
              <BusinessIcon />
            </StyledBadge>
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullScreen={fullScreen}
        aria-labelledby="responsive-extraFilter-dialog"
      >
        <DialogTitle aria-labelledby="extraFilter-title">
          {t('toolbar.labels.operativeFilters')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t('toolbar.extraFilters.body')}</DialogContentText>

          <AutocompleteSelector
            label={t('reports.labels.regionalManagement')}
            options={filteredRegionalManagements}
            value={filters.regionalManagements}
            handleSelect={selection =>
              handleSelectRegionalManagement(selection as RegionalManagement[])
            }
            groupBy={filters.countries.length > 1 ? option => option.country : undefined}
            renderGroup={filters.countries.length > 1 ? renderCountryGroup : undefined}
            renderOption={option => option.label}
            disabled={filters.division.length === 0}
            fullWidth
          />
          <AutocompleteSelector
            label={t('reports.labels.region')}
            options={filteredRegions}
            value={filters.regions}
            handleSelect={selection => handleSelectRegion(selection as Region[])}
            groupBy={filters.countries.length > 1 ? option => option.country : undefined}
            renderGroup={filters.countries.length > 1 ? renderCountryGroup : undefined}
            renderOption={option => option.label}
            disabled={filters.division.length === 0}
            fullWidth
          />
          <AutocompleteSelector
            label={t('reports.labels.management')}
            options={filteredManagements}
            value={filters.managements}
            handleSelect={selection => handleSelectManagements(selection as Management[])}
            groupBy={filters.countries.length > 1 ? option => option.country : undefined}
            renderGroup={filters.countries.length > 1 ? renderCountryGroup : undefined}
            renderOption={option => option.label}
            disabled={filters.division.length === 0}
            fullWidth
          />
          <AutocompleteSelector
            label={t('reports.labels.supervision')}
            options={filteredSupervisions}
            value={filters.supervisions}
            handleSelect={selection => handleSelectSupervisions(selection as Supervision[])}
            groupBy={filters.countries.length > 1 ? option => option.country : undefined}
            renderGroup={filters.countries.length > 1 ? renderCountryGroup : undefined}
            renderOption={option => option.label}
            disabled={filters.division.length === 0}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleReset}>
            {t('shared.buttons.cancel')}
          </Button>
          <Button id="operativeFilters-button-apply" color="default" onClick={handleApply}>
            {t('shared.buttons.apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default OperativeFilters;
