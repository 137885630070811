import React, { useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createStyles } from '@material-ui/styles';
import { Theme, makeStyles, FormControl } from '@material-ui/core';
import { useReport } from '../../context/report';
import { isMobileOnly } from 'react-device-detect';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import ptLocale from 'date-fns/locale/pt';
import frLocale from 'date-fns/locale/fr';
import DateFnsUtils from '@date-io/date-fns';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { isValidDate } from '../../utils/functions';
import { useLocation } from 'react-router-dom';
import { isSameDay } from 'date-fns';
import { quarterlySegments } from '../../utils/segments';

const localeMap: { [key: string]: Locale } = {
  en: enLocale,
  es: esLocale,
  pt: ptLocale,
  fr: frLocale,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayWrapper: {
      position: 'relative',
    },
    day: {
      width: 36,
      height: 36,
      fontSize: theme.typography.caption.fontSize,
      margin: '0 2px',
      color: 'inherit',
    },
    customDayHighlight: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '2px',
      right: '2px',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '50%',
    },
    nonCurrentMonthDay: {
      color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
      color: '#676767',
    },
    highlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    firstHighlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    endHighlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    },
    formControl: {
      margin: theme.spacing(1),
      maxWidth: 150,
    },
  })
);

interface Props {
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  disableFrom?: boolean;
  disableTo?: boolean;
}

const DateRangePicker = ({ error, setError, disableFrom, disableTo }: Props) => {
  const location = useLocation();
  const { updateFilters, filters } = useReport();
  const [openEndDate, setOpenEndDate] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { fromDate, toDate, disableToDate } = filters;
  const inExclusions = location.pathname.includes('exclusions');
  const [fromDateError, setFromDateError] = useState(false);
  const [toDateError, setToDateError] = useState(false);
  const inSameDay = isSameDay(filters.fromDate, filters.toDate);

  useEffect(() => {
    if (fromDateError || toDateError) {
      setError(true);
    } else {
      setError(false);
    }
    // eslint-disable-next-line
  }, [fromDateError, toDateError]);

  const getSegments = () => {
    if (inExclusions && !inSameDay) return quarterlySegments;
    return filters.segments;
  };

  const setStartDate = (date: Date) => {
    if (isValidDate(date)) {
      updateFilters({
        ...filters,
        fromDate: date,
        toDate: date,
        segments: getSegments(),
      });
      !disableToDate && setOpenEndDate(true);
    }
  };

  const setEndDate = (date: Date) => {
    if (isValidDate(date)) {
      updateFilters({
        ...filters,
        fromDate: fromDate,
        toDate: date,
        segments: getSegments(),
      });
      setOpenEndDate(false);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language.split('-')[0]]}>
      <FormControl className={classes.formControl}>
        <KeyboardDatePicker
          id="KeyboardDatePicker-from"
          onError={error => {
            setFromDateError(Boolean(error));
          }}
          invalidDateMessage={t('shared.messages.invalidDateMessage')}
          maxDateMessage={t('shared.messages.maxDateMessage')}
          minDateMessage={t('shared.messages.minDateMessage')}
          variant={isMobileOnly ? 'dialog' : 'inline'}
          label={t('shared.labels.from')}
          value={fromDate || new Date()}
          onChange={date => date && setStartDate(date)}
          autoOk
          disableFuture={!inExclusions}
          format={i18n.language.includes('en') ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
          disabled={disableFrom}
        />
      </FormControl>
      <FormControl
        className={classes.formControl}
        style={{ display: !disableToDate ? 'inherit' : 'none' }}
      >
        <KeyboardDatePicker
          id="KeyboardDatePicker-to"
          onError={error => {
            setToDateError(Boolean(error));
          }}
          invalidDateMessage={t('shared.messages.invalidDateMessage')}
          maxDateMessage={t('shared.messages.maxDateMessage')}
          minDateMessage={t('shared.messages.minDateMessage')}
          variant={isMobileOnly ? 'dialog' : 'inline'}
          minDate={fromDate}
          open={openEndDate}
          onOpen={() => setOpenEndDate(true)}
          onClose={() => setOpenEndDate(false)}
          label={t('shared.labels.to')}
          value={toDate || new Date()}
          onChange={date => date && setEndDate(date)}
          autoOk
          disableFuture={!inExclusions}
          format={i18n.language.includes('en') ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
          disabled={disableTo}
        />
      </FormControl>
    </MuiPickersUtilsProvider>
  );
};

export default DateRangePicker;
