import React, { useState } from 'react';
import Toolbar from '../../shared/Toolbar';
import { Column, SOSExclusions } from '../../../types';
import { useTranslation } from 'react-i18next';
import { useReport } from '../../../context/report';
import { Grid } from '@material-ui/core';
import ReportTable from '../../shared/Table';
import { formatISOdate } from '../../../utils/functions';
import FloatingActionButton from '../../fab';
import FilterListIcon from '@material-ui/icons/FilterList';
import FiltersDialog from '../../shared/dialogs/filters-dialog';
import withRestriction from '../../restricted';
import ExclusionsFormDialog from '../../shared/dialogs/exclusions-form-dialog';
import { quarterlySegments } from '../../../utils/segments';

function getService(service?: string) {
  switch (service) {
    case 'MFY':
      return 'MFY';
    case 'DT':
      return 'OEPE';
    case 'FC':
      return 'R2P';
    default:
      return '';
  }
}

function formattedTime(number: number) {
  if (number < 10) return `0${number}`;
  return `${number}`;
}

function getSegments(data?: string): string[] {
  let segments: string[] = quarterlySegments;
  if (!data || data === '') return segments;
  const newSegments = data.split(',').map(e => {
    const startHour = parseInt(e.slice(0, 2));
    const startMin = parseInt(e.slice(2));
    let endHour = 0;
    let endMin = 0;
    if (startMin === 45) {
      endHour = startHour === 23 ? 0 : startHour + 1;
      endMin = 0;
    } else {
      endHour = startHour;
      endMin = startMin + 15;
    }
    const start = `${formattedTime(startHour)}:${formattedTime(startMin)}`;
    const end = `${formattedTime(endHour)}:${formattedTime(endMin)}`;
    return `${start} - ${end}`;
  });
  return newSegments;
}

function getTitle(text: string, data?: SOSExclusions[]) {
  let services: string[] = [];
  let result = text;

  data?.forEach(item => {
    if (!services.includes(item.service)) services.push(item.service);
  });

  if (services.length > 1) {
    services.forEach((s, index) => {
      if (index > 0) result = result + ` + ${getService(s)}`;
      else result = result + `: ${getService(s)}`;
    });
  }

  return result;
}

const Detailed = () => {
  const { t, i18n } = useTranslation();
  const {
    updateFilters,
    sosExclusions,
    getExclusionsReport,
    newFiltersApplied,
    filters,
    numberOfAppliedFilters,
    loading,
  } = useReport();
  const [openReportSettings, setOpenReportSettings] = useState(false);
  const [openExclusionsForm, setOpenExclusionsForm] = useState(false);
  const [selectedRow, setSelectedRow] = useState<SOSExclusions>();

  const handleAddClick = () => {
    setSelectedRow(undefined);
    setOpenExclusionsForm(true);
  };

  const handleRowClick = (data: SOSExclusions) => {
    setSelectedRow(data);
    setOpenExclusionsForm(true);
    const fromDate = new Date(data.fromDate.replace('-', '/'));
    const segments: string[] = getSegments(data.frequency);
    if (data.toDate && data.toDate !== '') {
      const toDate = new Date(data.toDate.replace('-', '/'));
      updateFilters({ ...filters, fromDate, toDate, disableToDate: false, segments });
    } else {
      const toDate = new Date();
      updateFilters({ ...filters, fromDate, toDate, disableToDate: true, segments });
    }
  };

  const handleCloseForm = () => {
    setOpenExclusionsForm(false);
    setSelectedRow(undefined);
    updateFilters({ ...filters, segments: quarterlySegments });
  };

  const reportColumns: Column<SOSExclusions>[] = [
    {
      type: 'string',
      field: 'service',
      label: t('shared.labels.exclusionType'),
      format: row => getService(row.service),
    },
    {
      field: 'country',
      label: t('reports.labels.country'),
      type: 'string',
      format: row => (row.country && t(`shared.labels.${row.country}`)) || '0',
    },
    {
      type: 'string',
      field: 'storeAcronym',
      label: t('reports.labels.store'),
    },
    {
      type: 'string',
      field: 'fromDate',
      label: t('shared.labels.from'),
      format: row => (row.fromDate && formatISOdate(row.fromDate, i18n.language)) || '-',
    },
    {
      type: 'string',
      field: 'toDate',
      label: t('shared.labels.to'),
      format: row => (row.toDate && formatISOdate(row.toDate, i18n.language)) || '-',
    },
    {
      field: 'reason',
      type: 'string',
      label: t('dialogs.exclusions.reasonForExclusion'),
      // label: t('dialogs.exclusions.reason'),
    },
    {
      field: 'ticket',
      type: 'string',
      label: t('dialogs.exclusions.ticket'),
    },
    {
      field: 'userUpdate',
      type: 'string',
      label: t('dialogs.exclusions.userUpdate'),
    },
    {
      field: 'lastUpdate',
      type: 'string',
      label: t('dialogs.exclusions.lastUpdate'),
    },
    {
      field: 'frequency',
      type: 'string',
      label: t('reports.labels.frequency'),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Toolbar
            getData={() => getExclusionsReport()}
            dataIsLoading={loading}
            handleAddExclusion={handleAddClick}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportTable
            columns={reportColumns}
            data={sosExclusions}
            loading={loading}
            newFiltersApplied={newFiltersApplied}
            title={getTitle(t('reports.labels.exclusionsList'), sosExclusions)}
            clickeable
            onRowClick={handleRowClick}
          />
        </Grid>
      </Grid>
      <FloatingActionButton
        title={t('navigation.buttons.filters', { number: numberOfAppliedFilters })}
        handleClick={() => setOpenReportSettings(true)}
        handleCreateExclusion={() => setOpenExclusionsForm(true)}
        icon={<FilterListIcon />}
      />
      <FiltersDialog
        open={openReportSettings}
        setOpen={setOpenReportSettings}
        setOpenExclusions={setOpenExclusionsForm}
        fetchData={() => getExclusionsReport()}
      />
      <ExclusionsFormDialog
        open={openExclusionsForm}
        handleClose={handleCloseForm}
        data={selectedRow}
        loading={loading}
      />
    </>
  );
};

export default withRestriction(Detailed, 'SCREEN_MFY_REPORT');
