/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import React, { createContext, ReactNode, useState, useContext, useEffect } from 'react';
import { ChartData, ChartSeries, DashboardIndicator, ScatterData } from '../types';
import { MONTHS } from '../utils/constants';
import { useAlert } from './alert';
import { useApi } from './api';
import { useReport } from './report';
import { endOfMonth, format, getDaysInMonth, startOfMonth, startOfYear, subMonths } from 'date-fns';
import { useUser } from './user';
import { mgmtNameFormatter, regionNameFormatter } from '../utils/functions';

export type DashboardViewMode = 'general' | 'detailed';
interface DashboardContext {
  loadingYTD: boolean;
  loadingDT: boolean;
  loadingFC: boolean;
  viewMode: DashboardViewMode;
  mfyYtd: ChartData;
  oepeYtd: ChartData;
  r2pYtd: ChartData;
  divisionsIndicatorFC: ChartData;
  divisionsIndicatorDT: ChartData;
  countriesIndicatorFC: ChartData;
  countriesIndicatorDT: ChartData;
  lastMonthDtMfy: ChartData;
  lastMonthFcMfy: ChartData;
  lastMonthParking: ChartData;
  avgSandwitchIndicatorDT: ChartData;
  avgSandwitchIndicatorFC: ChartData;
  dailySandwitchIndicatorDT: ChartData;
  dailySandwitchIndicatorFC: ChartData;
  timesByRegionIndicatorMFY: ChartData;
  timesByRegionIndicatorDT: ChartData;
  timesByRegionIndicatorFC: ChartData;
  avgTimeIndicatorDT: ScatterData;
  oepeTotalAD: ChartData;
  applied: boolean;
  handleApplied: (value: boolean) => void;
  getIndicators: () => Promise<void>;
  getGeneralIndicatorsFC: (indicator: 'division' | 'country' | 'lastMonth') => Promise<void>;
  getDetailedIndicatorsFC: (
    indicator: 'avgSandwitch' | 'dailySandwitch' | 'timesByRegion'
  ) => Promise<void>;
  getDetailedIndicatorsMFY: () => Promise<void>;
  updateViewMode: (value: DashboardViewMode) => void;
  cleanDashboard: () => void;
}

const initialState: DashboardContext = {
  loadingYTD: false,
  loadingDT: false,
  loadingFC: false,
  viewMode: 'general',
  mfyYtd: { series: [], categories: [], ytd: 0 },
  oepeYtd: { series: [], categories: [], ytd: 0 },
  r2pYtd: { series: [], categories: [], ytd: 0 },
  divisionsIndicatorFC: { series: [], categories: [] },
  divisionsIndicatorDT: { series: [], categories: [] },
  countriesIndicatorFC: { series: [], categories: [] },
  countriesIndicatorDT: { series: [], categories: [] },
  lastMonthDtMfy: { series: [], categories: [] },
  lastMonthFcMfy: { series: [], categories: [] },
  lastMonthParking: { series: [], categories: [] },
  avgSandwitchIndicatorDT: { series: [], categories: [] },
  avgSandwitchIndicatorFC: { series: [], categories: [] },
  dailySandwitchIndicatorDT: { series: [], categories: [] },
  dailySandwitchIndicatorFC: { series: [], categories: [] },
  timesByRegionIndicatorMFY: { series: [], categories: [] },
  timesByRegionIndicatorDT: { series: [], categories: [] },
  timesByRegionIndicatorFC: { series: [], categories: [] },
  avgTimeIndicatorDT: { series: [], categories: [] },
  oepeTotalAD: { series: [], categories: [] },
  applied: false,
  handleApplied: () => {},
  getIndicators: () => new Promise<void>(() => {}),
  getGeneralIndicatorsFC: () => new Promise<void>(() => {}),
  getDetailedIndicatorsFC: () => new Promise<void>(() => {}),
  getDetailedIndicatorsMFY: () => new Promise<void>(() => {}),
  updateViewMode: () => {},
  cleanDashboard: () => {},
};

const DashboardContext = createContext(initialState);

interface Provider {
  children: ReactNode;
}

export const DashboardProvider = ({ children }: Provider) => {
  const {
    fetchIndicatorsMFY,
    fetchIndicatorsDT,
    fetchIndicatorsFC,
    fetchIndicatorsDTMFY,
    fetchIndicatorsFCMFY,
    fetchProjectionsDT,
  } = useApi();
  const { getFormattedFilters } = useReport();
  const { permissions } = useUser();
  const { showError } = useAlert();

  const [applied, setApplied] = useState(false);
  const [viewMode, setViewMode] = useState<DashboardViewMode>('general');
  const [loadingYTD, setLoadingYTD] = useState(false);
  const [loadingDT, setLoadingDT] = useState(false);
  const [loadingFC, setLoadingFC] = useState(false);

  // YTD
  const [mfyYtd, setMfyYTD] = useState<ChartData>(initialState.mfyYtd);
  const [oepeYtd, setOepeYTD] = useState<ChartData>(initialState.oepeYtd);
  const [r2pYtd, setR2pYTD] = useState<ChartData>(initialState.r2pYtd);

  // GENERAL VIEW
  const [divisionsIndicatorDT, setDivisionsIndicatorDT] = useState<ChartData>(
    initialState.divisionsIndicatorDT
  );
  const [divisionsIndicatorFC, setDivisionsIndicatorFC] = useState<ChartData>(
    initialState.divisionsIndicatorFC
  );
  const [countriesIndicatorDT, setCountriesIndicatorDT] = useState<ChartData>(
    initialState.countriesIndicatorDT
  );
  const [countriesIndicatorFC, setCountriesIndicatorFC] = useState<ChartData>(
    initialState.countriesIndicatorFC
  );
  const [lastMonthDtMfy, setLastMonthDtMfy] = useState<ChartData>(initialState.lastMonthDtMfy);
  const [lastMonthFcMfy, setLastMonthFcMfy] = useState<ChartData>(initialState.lastMonthDtMfy);
  const [lastMonthParking, setLastMonthParking] = useState<ChartData>(
    initialState.lastMonthParking
  );
  const [oepeTotalAD, setOepeTotalAD] = useState<ChartData>(initialState.oepeTotalAD);

  // DETAILED VIEW
  const [avgSandwitchIndicatorDT, setAvgSandwitchIndicatorDT] = useState<ChartData>(
    initialState.avgSandwitchIndicatorDT
  );
  const [avgSandwitchIndicatorFC, setAvgSandwitchIndicatorFC] = useState<ChartData>(
    initialState.avgSandwitchIndicatorFC
  );
  const [avgTimeIndicatorDT, setAvgTimeIndicatorDT] = useState<ScatterData>(
    initialState.avgTimeIndicatorDT
  );
  const [dailySandwitchIndicatorDT, setDailySandwitchIndicatorDT] = useState<ChartData>(
    initialState.dailySandwitchIndicatorDT
  );
  const [dailySandwitchIndicatorFC, setDailySandwitchIndicatorFC] = useState<ChartData>(
    initialState.dailySandwitchIndicatorFC
  );
  const [timesByRegionIndicatorMFY, setTimesByRegionIndicatorMFY] = useState<ChartData>(
    initialState.timesByRegionIndicatorMFY
  );
  const [timesByRegionIndicatorDT, setTimesByRegionIndicatorDT] = useState<ChartData>(
    initialState.timesByRegionIndicatorDT
  );
  const [timesByRegionIndicatorFC, setTimesByRegionIndicatorFC] = useState<ChartData>(
    initialState.timesByRegionIndicatorFC
  );

  useEffect(() => {
    if (!permissions.includes('FILTER_OPSTRUCTURE')) {
      updateViewMode('detailed');
    }
  }, [permissions]);

  function getDates() {
    const isDayOne = new Date().getDate() === 1;
    const fromCurrentYear = format(startOfYear(new Date()), 'yyyy-MM-dd');
    const toCurrentMonth = format(endOfMonth(new Date()), 'yyyy-MM-dd');
    const fromCurrentMonth = isDayOne
      ? format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
      : format(startOfMonth(new Date()), 'yyyy-MM-dd');
    const fromLastMonths = format(
      startOfMonth(subMonths(new Date(), isDayOne ? 3 : 2)),
      'yyyy-MM-dd'
    );
    return { fromCurrentYear, toCurrentMonth, fromCurrentMonth, fromLastMonths };
  }

  const handleApplied = (value: boolean) => {
    setApplied(value);
  };

  const cleanDashboard = () => {
    setMfyYTD(initialState.mfyYtd);
    setOepeYTD(initialState.oepeYtd);
    setR2pYTD(initialState.r2pYtd);
    setDivisionsIndicatorFC(initialState.divisionsIndicatorFC);
    setDivisionsIndicatorDT(initialState.divisionsIndicatorDT);
    setCountriesIndicatorFC(initialState.countriesIndicatorFC);
    setCountriesIndicatorDT(initialState.countriesIndicatorDT);
    setLastMonthDtMfy(initialState.lastMonthDtMfy);
    setLastMonthFcMfy(initialState.lastMonthDtMfy);
    setLastMonthParking(initialState.lastMonthParking);
    setAvgSandwitchIndicatorDT(initialState.avgSandwitchIndicatorDT);
    setAvgSandwitchIndicatorFC(initialState.avgSandwitchIndicatorFC);
    setAvgTimeIndicatorDT(initialState.avgTimeIndicatorDT);
    setDailySandwitchIndicatorDT(initialState.dailySandwitchIndicatorDT);
    setDailySandwitchIndicatorFC(initialState.dailySandwitchIndicatorFC);
    setTimesByRegionIndicatorMFY(initialState.timesByRegionIndicatorMFY);
    setTimesByRegionIndicatorDT(initialState.timesByRegionIndicatorDT);
    setTimesByRegionIndicatorFC(initialState.timesByRegionIndicatorFC);
    setOepeTotalAD(initialState.oepeTotalAD);
  };

  const ytdTransformedModel = (
    results: DashboardIndicator[],
    totals: DashboardIndicator[],
    indicator: string
  ) => {
    const timeline: ChartData = {
      series: [],
      categories: [],
      ytd: totals[0] ? (totals[0][indicator] as number) : 0,
    };

    const hashmap: { [key: string]: { [key: string]: number } } = {};
    results.forEach(item => {
      if (item.month) {
        if (!hashmap[item.month]) hashmap[item.month] = {};
        if (item[indicator]) hashmap[item.month][indicator] = (item[indicator] as number) || 0;
      }
    });
    const categories: string[] = [...MONTHS];
    categories.forEach(month => {
      timeline.categories.push(month);
      const serieIndex = timeline.series.findIndex(s => s.name === indicator);
      if (hashmap[month] && hashmap[month][indicator]) {
        if (serieIndex > -1) {
          timeline.series[serieIndex].data.push(hashmap[month][indicator]);
        } else {
          timeline.series.push({
            name: indicator,
            data: [hashmap[month][indicator]],
            type: 'area',
          });
        }
      } else {
        if (serieIndex > -1) {
          timeline.series[serieIndex].data.push(0);
        } else {
          timeline.series.push({ name: indicator, data: [0], type: 'area' });
        }
      }
    });
    return timeline;
  };

  const proyectionsTransformedModel = (timeline: ChartData, results: DashboardIndicator[]) => {
    const hashmap: { [key: string]: number } = {};
    results.forEach(item => {
      if (item['market']) {
        hashmap[item['market']] = item['projection'] || 0;
      }
    });
    const serie: ChartSeries = { name: 'Projections', data: [], type: 'area' };
    timeline.categories.forEach(key => {
      serie.data.push(hashmap[key] || 0);
    });
    timeline.series.push(serie);
    return timeline;
  };

  const generalMixedTransformedModel = (
    results: DashboardIndicator[],
    groupBy: string,
    indicator: string,
    proyections?: DashboardIndicator[]
  ) => {
    const timeline: ChartData = {
      series: [],
      categories: [],
    };
    const categories: string[] = [];
    const seriesName: string[] = [];
    const hashmap: { [key: string]: { [key: string]: number } } = {};
    results.forEach(item => {
      if (item[groupBy] && item.month) {
        if (!categories.includes(item[groupBy] as string)) categories.push(item[groupBy] as string);
        if (!seriesName.includes(item.month)) seriesName.push(item.month);
        if (!hashmap[item[groupBy] as string]) hashmap[item[groupBy] as string] = {};
        hashmap[item[groupBy] as string][item.month] = (item[indicator] as number) || 0;
      }
    });
    categories.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
    categories.forEach(key => {
      timeline.categories.push(key);
      const series = hashmap[key];
      let remainingSeries = [...seriesName];
      Object.keys(series).forEach(key => {
        remainingSeries = remainingSeries.filter(e => e !== key);
        const serieIndex = timeline.series.findIndex(s => s.name === key);
        if (serieIndex > -1) {
          timeline.series[serieIndex].data.push(series[key]);
        } else {
          timeline.series.push({ name: key, data: [series[key]], type: 'column' });
        }
      });
      remainingSeries.forEach(serie => {
        const serieIndex = timeline.series.findIndex(s => s.name === serie);
        if (serieIndex > -1) {
          timeline.series[serieIndex].data.push(0);
        } else {
          timeline.series.push({ name: serie, data: [0] });
        }
      });
    });
    let sortedMonths: string[] = [];
    const currentMonth = new Date().getMonth();
    switch (currentMonth) {
      case 0:
        sortedMonths = [MONTHS[10], MONTHS[11], MONTHS[0]];
        break;
      case 1:
        sortedMonths = [MONTHS[11], MONTHS[0], MONTHS[1]];
        break;
      default:
        sortedMonths = [MONTHS[currentMonth - 2], MONTHS[currentMonth - 1], MONTHS[currentMonth]];
        break;
    }
    timeline.series.sort((a, b) => {
      if (sortedMonths.findIndex(e => e === a.name) > sortedMonths.findIndex(e => e === b.name))
        return 1;
      if (sortedMonths.findIndex(e => e === a.name) < sortedMonths.findIndex(e => e === b.name))
        return -1;
      return 0;
    });
    if (indicator === 'pfwdPercent') {
      const lastIndex = timeline.series.length - 1;
      // Chequea caso de primer dia del mes
      if (timeline.series[lastIndex]?.name === MONTHS[currentMonth]) {
        timeline.series = [{ ...timeline.series[lastIndex] }];
      } else {
        timeline.series = [];
      }
    } else if (proyections && proyections.length > 0) {
      return proyectionsTransformedModel(timeline, proyections);
    }
    return timeline;
  };

  const stackedTransformedModel = (
    results: DashboardIndicator[],
    groupBy: string,
    indicator: string
  ) => {
    const timeline: ChartData = {
      series: [],
      categories: [],
    };
    const categories: string[] = [];
    const seriesName: string[] = [];
    const hashmap: { [key: string]: { [key: string]: number } } = {};
    results.forEach(item => {
      if (item[groupBy] && item.month) {
        if (!categories.includes(item[groupBy] as string)) categories.push(item[groupBy] as string);
        if (!seriesName.includes(indicator.toUpperCase())) seriesName.push(indicator.toUpperCase());
        if (!seriesName.includes('MFY')) seriesName.push('MFY');

        if (!hashmap[item[groupBy] as string]) hashmap[item[groupBy] as string] = {};
        hashmap[item[groupBy] as string][indicator.toUpperCase()] =
          (item[indicator] as number) || 0;
        if (!hashmap[item[groupBy] as string]) hashmap[item[groupBy] as string] = {};
        hashmap[item[groupBy] as string]['MFY'] = (item['mfy'] as number) || 0;
      }
    });
    categories.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
    categories.forEach(key => {
      timeline.categories.push(key);
      const series = hashmap[key];
      let remainingSeries = [...seriesName];
      Object.keys(series).forEach(key => {
        remainingSeries = remainingSeries.filter(e => e !== key);
        const serieIndex = timeline.series.findIndex(s => s.name === key);
        if (serieIndex > -1) {
          timeline.series[serieIndex].data.push(series[key]);
        } else {
          timeline.series.push({ name: key, data: [series[key]] });
        }
      });
      remainingSeries.forEach(serie => {
        const serieIndex = timeline.series.findIndex(s => s.name === serie);
        if (serieIndex > -1) {
          timeline.series[serieIndex].data.push(0);
        } else {
          timeline.series.push({ name: serie, data: [0] });
        }
      });
    });
    return timeline;
  };

  const detailedMixedTransformedModel = (
    results: DashboardIndicator[],
    indicator: string,
    groupBy: string
  ) => {
    const timeline: ChartData = {
      series: [],
      categories: [],
    };
    let categories: string[] = [];
    const chartTypes: { [key: string]: string } = {
      Sandwitchs: 'column',
      MFY: 'line',
      OEPE: 'area',
      R2P: 'area',
    };
    const hashmap: { [key: string]: { [key: string]: number } } = {};
    results.forEach(item => {
      if (item[groupBy]) {
        if (!categories.includes(item[groupBy] as string)) categories.push(item[groupBy] as string);
        if (!hashmap['Sandwitchs']) hashmap['Sandwitchs'] = {};
        if (!hashmap[indicator.toUpperCase()]) hashmap[indicator.toUpperCase()] = {};
        if (!hashmap['MFY']) hashmap['MFY'] = {};
        hashmap[indicator.toUpperCase()][item[groupBy] as string] =
          (item[indicator] as number) || 0;
        hashmap['MFY'][item[groupBy] as string] = item.mfy || 0;
        hashmap['Sandwitchs'][item[groupBy] as string] = item.avg || 0;
      }
    });
    if (groupBy === 'month') {
      const currentMonth = new Date().getMonth();
      categories = [...MONTHS.slice(0, currentMonth + 1)];
    } else {
      categories.sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
    }
    categories.forEach(c => {
      timeline.categories.push(c);
      Object.keys(hashmap).forEach(key => {
        const serie = hashmap[key];
        const serieIndex = timeline.series.findIndex(s => s.name === key);
        if (serieIndex > -1) {
          timeline.series[serieIndex].data.push(serie[c] || 0);
        } else {
          timeline.series.push({ name: key, data: [serie[c] || 0], type: chartTypes[key] });
        }
      });
    });
    return timeline;
  };

  const timesByRegionTransformedModel = (results: DashboardIndicator[], indicator: string) => {
    const timeline: ChartData = {
      series: [],
      categories: [],
    };
    const currentMonth = new Date().getMonth();
    const categories = [...MONTHS.slice(0, currentMonth + 1)];
    const hashmap: { [key: string]: { [key: string]: number } } = {};
    results.forEach(item => {
      if (item.month && item.region) {
        // if (!categories.includes(item.month)) categories.push(item.month);
        if (!hashmap[item.region]) hashmap[item.region] = {};
        hashmap[item.region][item.month as string] = (item[indicator] as number) || 0;
      }
    });
    categories.forEach(c => {
      timeline.categories.push(c);
      Object.keys(hashmap).forEach(key => {
        const serie = hashmap[key];
        const serieIndex = timeline.series.findIndex(s => s.name === key);
        if (serieIndex > -1) {
          timeline.series[serieIndex].data.push(serie[c] || 0);
        } else {
          timeline.series.push({ name: key, data: [serie[c] || 0], type: 'line' });
        }
      });
    });
    return timeline;
  };

  const columnsTransformedModel = (results: DashboardIndicator[]) => {
    const timeline: ChartData = {
      series: [{ name: 'OEPE', data: [] }],
      categories: [],
    };
    const categories: string[] = [];
    const hashmap: { [key: string]: number } = {};
    results.forEach(item => {
      if (item['country']) {
        if (!categories.includes(item['country'])) categories.push(item['country']);
        hashmap[item['country']] = item.oepe || 0;
      } else if (item['region']) {
        const regionPrefix = regionNameFormatter(item['region']);
        if (!categories.includes(regionPrefix)) categories.push(regionPrefix);
        hashmap[regionPrefix] = item.oepe || 0;
      } else if (item['mgmt']) {
        const mgmtPrefix = mgmtNameFormatter(item['mgmt']);
        if (!categories.includes(mgmtPrefix)) categories.push(mgmtPrefix);
        hashmap[mgmtPrefix] = item.oepe || 0;
      } else {
        hashmap['Total AD'] = item.oepe || 0;
      }
    });
    categories.sort((a, b) => {
      if (hashmap[a] > hashmap[b]) return 1;
      if (hashmap[a] < hashmap[b]) return -1;
      return 0;
    });
    categories.forEach(c => {
      timeline.categories.push(c);
      timeline.series[0].data.push(hashmap[c]);
    });
    timeline.categories.push('Total AD');
    timeline.series[0].data.push(hashmap['Total AD'] || 0);
    return timeline;
  };

  // YTD INDICATORS

  const getIndicatorsYTD = async () => {
    try {
      setLoadingYTD(true);
      const { fromCurrentYear, toCurrentMonth } = getDates();
      const ytdRequests = [
        fetchIndicatorsMFY({
          ...getFormattedFilters(),
          groupBy: 'month',
          fromDate: fromCurrentYear,
          toDate: toCurrentMonth,
        }),
        fetchIndicatorsDT({
          ...getFormattedFilters(),
          groupBy: 'month',
          fromDate: fromCurrentYear,
          toDate: toCurrentMonth,
        }),
        fetchIndicatorsFC({
          ...getFormattedFilters(),
          groupBy: 'month',
          fromDate: fromCurrentYear,
          toDate: toCurrentMonth,
        }),
      ];
      const [mfyYtdResponse, oepeYtdResponse, r2pYtdResponse] = await Promise.all(ytdRequests);
      setMfyYTD(
        ytdTransformedModel(mfyYtdResponse.data.results, mfyYtdResponse.data.result_totals, 'mfy')
      );
      setOepeYTD(
        ytdTransformedModel(
          oepeYtdResponse.data.results,
          oepeYtdResponse.data.result_totals,
          'oepe'
        )
      );
      setR2pYTD(
        ytdTransformedModel(r2pYtdResponse.data.results, r2pYtdResponse.data.result_totals, 'r2p')
      );
    } catch (error) {
      showError(error);
    } finally {
      setLoadingYTD(false);
    }
  };

  // GENERAL INDICATORS VIEW

  const scatterTransformedModel = (results: DashboardIndicator[]) => {
    const timeline: ScatterData = {
      series: [],
      categories: [],
    };
    const categories: string[] = [];
    const hashmap: { [key: string]: { oepe: number; mfy: number } } = {};
    results.forEach(item => {
      if (item.month && item.store) {
        if (!categories.includes(item.month)) categories.push(item.month);
        hashmap[item.store] = { oepe: item.oepe || 0, mfy: item.mfy || 0 };
      }
    });
    Object.keys(hashmap).forEach(key => {
      const serie = hashmap[key];
      const data = [serie?.oepe || 0, serie?.mfy || 0];
      const serieIndex = timeline.series.findIndex(s => s.name === key);
      if (serieIndex > -1) {
        timeline.series[serieIndex].data.push(data);
      } else {
        timeline.series.push({ name: key, data: [data] });
      }
    });
    return timeline;
  };

  const getGeneralIndicatorsDT = async () => {
    try {
      setLoadingDT(true);
      const { fromCurrentMonth, fromLastMonths, toCurrentMonth } = getDates();
      const dtRequests = [
        fetchIndicatorsDT({
          ...getFormattedFilters(),
          groupBy: 'division, month',
          fromDate: fromLastMonths,
          toDate: toCurrentMonth,
        }),
        fetchIndicatorsDT({
          ...getFormattedFilters(),
          groupBy: 'country, month',
          fromDate: fromLastMonths,
          toDate: toCurrentMonth,
        }),
        fetchIndicatorsDTMFY({
          ...getFormattedFilters(),
          groupBy: 'country, month',
          fromDate: fromCurrentMonth,
          toDate: toCurrentMonth,
        }),
        fetchProjectionsDT({
          ...getFormattedFilters(),
          groupBy: 'market',
          service: 'oepe',
          projType: 'division',
        }),
        fetchProjectionsDT({
          ...getFormattedFilters(),
          groupBy: 'market',
          service: 'oepe',
          projType: 'pais',
        }),
        fetchIndicatorsDT({
          ...getFormattedFilters(),
          division: 'SLA,NLA,BRA',
          country: '',
          store: '',
          groupBy: 'country',
          fromDate: fromCurrentMonth,
          toDate: toCurrentMonth,
        }),
        fetchIndicatorsDT({
          ...getFormattedFilters(),
          division: 'NLA,BRA',
          country: 'MX,BR',
          store: '',
          groupBy: 'region',
          fromDate: fromCurrentMonth,
          toDate: toCurrentMonth,
        }),
        fetchIndicatorsDT({
          ...getFormattedFilters(),
          division: 'NLA',
          country: 'VI',
          store: '',
          groupBy: 'mgmt',
          fromDate: fromCurrentMonth,
          toDate: toCurrentMonth,
        }),
      ];
      const [
        divisionsIndicatorDTResponse,
        countriesIndicatorDTResponse,
        lastMonthDtMfyResponse,
        divisionsProjections,
        countriesProjections,
        oepeTotalADByCountry,
        oepeTotalADByRegion,
        oepeTotalADByMgmt,
      ] = await Promise.all(dtRequests);
      setDivisionsIndicatorDT(
        generalMixedTransformedModel(
          divisionsIndicatorDTResponse.data.results,
          'division',
          'oepe',
          divisionsProjections.data.results
        )
      );
      setLastMonthDtMfy(
        stackedTransformedModel(lastMonthDtMfyResponse.data.results, 'country', 'oepe')
      );
      setCountriesIndicatorDT(
        generalMixedTransformedModel(
          countriesIndicatorDTResponse.data.results,
          'country',
          'oepe',
          countriesProjections.data.results
        )
      );
      setLastMonthParking(
        generalMixedTransformedModel(
          countriesIndicatorDTResponse.data.results,
          'country',
          'pfwdPercent'
        )
      );
      setOepeTotalAD(
        columnsTransformedModel([
          ...oepeTotalADByCountry.data.results,
          ...oepeTotalADByRegion.data.results,
          ...oepeTotalADByCountry.data.result_totals,
          ...oepeTotalADByMgmt.data.results,
        ])
      );
    } catch (error) {
      showError(error);
    } finally {
      setLoadingDT(false);
    }
  };

  const getGeneralIndicatorsFC = async (indicator: 'division' | 'country' | 'lastMonth') => {
    try {
      setLoadingFC(true);
      const { fromCurrentYear, fromCurrentMonth, fromLastMonths, toCurrentMonth } = getDates();

      switch (indicator) {
        case 'division':
          const divisionsIndicatorFCResponse = await fetchIndicatorsFC({
            ...getFormattedFilters(),
            groupBy: 'division, month',
            fromDate: fromLastMonths,
            toDate: toCurrentMonth,
          });
          setDivisionsIndicatorFC(
            generalMixedTransformedModel(
              divisionsIndicatorFCResponse.data.results,
              'division',
              'r2p'
            )
          );
          break;
        case 'country':
          const countriesIndicatorFCResponse = await fetchIndicatorsFC({
            ...getFormattedFilters(),
            groupBy: 'country, month',
            fromDate: fromLastMonths,
            toDate: toCurrentMonth,
          });
          setCountriesIndicatorFC(
            generalMixedTransformedModel(
              countriesIndicatorFCResponse.data.results,
              'country',
              'r2p'
            )
          );
          break;
        case 'lastMonth':
          const lastMonthFcMfyResponse = await fetchIndicatorsFCMFY({
            ...getFormattedFilters(),
            groupBy: 'country, month',
            fromDate: fromCurrentMonth,
            toDate: toCurrentMonth,
          });
          setLastMonthFcMfy(
            stackedTransformedModel(lastMonthFcMfyResponse.data.results, 'country', 'r2p')
          );
          break;

        default:
          break;
      }
    } catch (error) {
      showError(error);
    } finally {
      setLoadingFC(false);
    }
  };

  const getGeneralIndicators = () => {
    getIndicatorsYTD();
    getGeneralIndicatorsDT();
  };

  // DETAILED INDICATORS VIEW

  const getDetailedIndicatorsMFY = async () => {
    try {
      setLoadingYTD(true);
      const { fromCurrentYear, toCurrentMonth } = getDates();
      const mfyRequests = [
        fetchIndicatorsMFY({
          ...getFormattedFilters(),
          groupBy: 'month, region',
          fromDate: fromCurrentYear,
          toDate: toCurrentMonth,
        }),
      ];
      const [timesByRegionIndicatorResponse] = await Promise.all(mfyRequests);
      setTimesByRegionIndicatorMFY(
        timesByRegionTransformedModel(timesByRegionIndicatorResponse.data.results, 'mfy')
      );
    } catch (error) {
      showError(error);
    } finally {
      setLoadingYTD(false);
    }
  };

  const getDetailedIndicatorsDT = async () => {
    try {
      setLoadingDT(true);
      const { fromCurrentYear, fromCurrentMonth, toCurrentMonth } = getDates();
      const dtRequests = [
        fetchIndicatorsDTMFY({
          ...getFormattedFilters(),
          groupBy: 'month',
          fromDate: fromCurrentYear,
          toDate: toCurrentMonth,
        }),
        fetchIndicatorsDTMFY({
          ...getFormattedFilters(),
          groupBy: 'day',
          fromDate: fromCurrentMonth,
          toDate: toCurrentMonth,
        }),
        fetchIndicatorsDT({
          ...getFormattedFilters(),
          groupBy: 'month, region',
          fromDate: fromCurrentYear,
          toDate: toCurrentMonth,
        }),
        fetchIndicatorsDTMFY({
          ...getFormattedFilters(),
          groupBy: 'month, store',
          fromDate: fromCurrentMonth,
          toDate: toCurrentMonth,
        }),
      ];
      const [
        avgSandwitchIndicatorResponse,
        dailySandwitchIndicatorResponse,
        timesByRegionIndicatorResponse,
        avgTimeIndicatorResponse,
      ] = await Promise.all(dtRequests);
      setAvgSandwitchIndicatorDT(
        detailedMixedTransformedModel(avgSandwitchIndicatorResponse.data.results, 'oepe', 'month')
      );
      setDailySandwitchIndicatorDT(
        detailedMixedTransformedModel(dailySandwitchIndicatorResponse.data.results, 'oepe', 'day')
      );
      setTimesByRegionIndicatorDT(
        timesByRegionTransformedModel(timesByRegionIndicatorResponse.data.results, 'oepe')
      );
      setAvgTimeIndicatorDT(scatterTransformedModel(avgTimeIndicatorResponse.data.results));
    } catch (error) {
      showError(error);
    } finally {
      setLoadingDT(false);
    }
  };

  const getDetailedIndicatorsFC = async (
    indicator: 'avgSandwitch' | 'dailySandwitch' | 'timesByRegion'
  ) => {
    try {
      setLoadingFC(true);
      const { fromCurrentYear, fromCurrentMonth, toCurrentMonth } = getDates();

      switch (indicator) {
        case 'avgSandwitch':
          const avgSandwitchIndicatorResponse = await fetchIndicatorsFCMFY({
            ...getFormattedFilters(),
            groupBy: 'month',
            fromDate: fromCurrentYear,
            toDate: toCurrentMonth,
          });
          setAvgSandwitchIndicatorFC(
            detailedMixedTransformedModel(
              avgSandwitchIndicatorResponse.data.results,
              'r2p',
              'month'
            )
          );
          break;
        case 'dailySandwitch':
          const dailySandwitchIndicatorResponse = await fetchIndicatorsFCMFY({
            ...getFormattedFilters(),
            groupBy: 'day',
            fromDate: fromCurrentMonth,
            toDate: toCurrentMonth,
          });
          setDailySandwitchIndicatorFC(
            detailedMixedTransformedModel(
              dailySandwitchIndicatorResponse.data.results,
              'r2p',
              'day'
            )
          );
          break;
        case 'timesByRegion':
          const timesByRegionIndicatorResponse = await fetchIndicatorsFC({
            ...getFormattedFilters(),
            groupBy: 'month, region',
            fromDate: fromCurrentYear,
            toDate: toCurrentMonth,
          });
          setTimesByRegionIndicatorFC(
            timesByRegionTransformedModel(timesByRegionIndicatorResponse.data.results, 'r2p')
          );
          break;

        default:
          break;
      }
    } catch (error) {
      showError(error);
    } finally {
      setLoadingFC(false);
    }
  };

  const getDetailedIndicators = () => {
    getIndicatorsYTD();
    getDetailedIndicatorsDT();
  };

  const getIndicators = async () => {
    switch (viewMode) {
      case 'general':
        getGeneralIndicators();
        break;
      case 'detailed':
        getDetailedIndicators();
        break;
      default:
        break;
    }
  };

  const updateViewMode = (value: DashboardViewMode) => {
    setViewMode(value);
  };

  return (
    <DashboardContext.Provider
      value={{
        loadingYTD,
        loadingDT,
        loadingFC,
        viewMode,
        mfyYtd,
        oepeYtd,
        r2pYtd,
        divisionsIndicatorFC,
        divisionsIndicatorDT,
        countriesIndicatorFC,
        countriesIndicatorDT,
        lastMonthDtMfy,
        lastMonthFcMfy,
        lastMonthParking,
        avgSandwitchIndicatorDT,
        avgSandwitchIndicatorFC,
        avgTimeIndicatorDT,
        dailySandwitchIndicatorDT,
        dailySandwitchIndicatorFC,
        timesByRegionIndicatorDT,
        timesByRegionIndicatorFC,
        timesByRegionIndicatorMFY,
        oepeTotalAD,
        applied,
        handleApplied,
        getIndicators,
        getGeneralIndicatorsFC,
        getDetailedIndicatorsFC,
        getDetailedIndicatorsMFY,
        updateViewMode,
        cleanDashboard,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => useContext(DashboardContext);
