import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  useMediaQuery,
  useTheme,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Tooltip,
  IconButton,
  withStyles,
  Badge,
  List,
  ListSubheader,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useInstitutional } from '../../../context/institutional';
import { useReport } from '../../../context/report';
import CompanyIcon from '@material-ui/icons/HomeWork'; // Store HomeWork Public Fastfood
import { Franchise, StoreAgreement } from '../../../types';
import AutocompleteSelector from '../../selectors/autocomplete';
import SimpleSelector from '../../selectors/simple';
import { AutocompleteRenderGroupParams } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 160,
    },
    iconButton: {
      margin: theme.spacing(1),
    },
  })
);

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  })
)(Badge);

const CompanyFilters = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const {
    loading: loadingInstitutional,
    filteredFranchises,
    filteredAgreements,
  } = useInstitutional();
  const { filters, updateFilters } = useReport();
  const { division, franchises } = filters;
  const [openDialog, setOpenDialog] = useState(false);
  const [numberOfCompanyFilters, setNumberOfCompanyFilters] = useState(0);

  useEffect(() => {
    let newNumber = 0;
    filters.storeAgreements.length > 0 && newNumber++;
    filters.franchises.length > 0 && newNumber++;
    setNumberOfCompanyFilters(newNumber);
  }, [filters.storeAgreements, filters.franchises]);

  const handleReset = () => {
    updateFilters({
      franchises: [],
      storeAgreements: [],
    });
    setOpenDialog(false);
  };

  const handleApply = () => {
    setOpenDialog(false);
  };

  const handleSelectStoreAgreements = (selection: StoreAgreement[]) => {
    updateFilters({
      storeAgreements: selection,
      franchises: franchises.filter(f =>
        selection.map(s => s).includes(f.agreementId?.toString() as StoreAgreement)
      ),
      stores: [],
    });
  };

  const handleSelectFranchises = (selection: Franchise[]) => {
    updateFilters({ franchises: selection, stores: [] });
  };

  const renderCountryGroup = (element: AutocompleteRenderGroupParams) => (
    <List
      key={element.key}
      subheader={<ListSubheader>{t(`shared.labels.${element.group}`)}</ListSubheader>}
    >
      {element.children}
    </List>
  );

  return (
    <Grid item className={classes.iconButton}>
      <Tooltip title={t('toolbar.labels.companyFilters').toString()} placement="bottom">
        <span>
          <IconButton
            id="companyFilters-icon-button"
            aria-label="extra-filters"
            onClick={() => setOpenDialog(true)}
            disabled={division.length === 0 || loadingInstitutional}
          >
            <StyledBadge badgeContent={numberOfCompanyFilters} color="secondary">
              <CompanyIcon />
            </StyledBadge>
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullScreen={fullScreen}
        aria-labelledby="responsive-extraFilter-dialog"
      >
        <DialogTitle aria-labelledby="extraFilter-title">
          {t('toolbar.labels.companyFilters')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t('toolbar.labels.companyFiltersSubtitle')}</DialogContentText>
          <SimpleSelector
            label={t('reports.labels.storeType')}
            options={filteredAgreements}
            value={filters.storeAgreements}
            handleSelect={selection => handleSelectStoreAgreements(selection as StoreAgreement[])}
            optionFormatter={option => t(`shared.labels.storeAgreement.${option}`)}
            disabled={filters.division.length === 0}
            fullWidth
            multiple
          />
          <AutocompleteSelector
            label={t('reports.labels.franchises')}
            options={filteredFranchises}
            value={filters.franchises}
            handleSelect={handleSelectFranchises}
            groupBy={filters.countries.length > 1 ? option => option.country : undefined}
            renderGroup={filters.countries.length > 1 ? renderCountryGroup : undefined}
            renderOption={option => option.label}
            disabled={filters.division.length === 0}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleReset}>
            {t('shared.buttons.cancel')}
          </Button>
          <Button id="companyFilters-button-apply" color="default" onClick={handleApply}>
            {t('shared.buttons.apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CompanyFilters;
