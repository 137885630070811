import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDashboard, DashboardViewMode } from '../../../context/dashboard';
import { useHistory } from 'react-router-dom';
import { useReport } from '../../../context/report';
import { useUser } from '../../../context/user';

const DashboardSelector = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { viewMode, updateViewMode } = useDashboard();
  const { filters, updateFilters } = useReport();
  const { permissions } = useUser();
  const showGeneral = permissions.includes('FILTER_OPSTRUCTURE');

  const handleChange = (value: DashboardViewMode) => {
    updateViewMode(value);
    if (value === 'detailed') {
      updateFilters({
        ...filters,
        division: filters.division[0] ? [filters.division[0]] : [],
        countries: filters.countries[0] ? [filters.countries[0]] : [],
      });
    }
    history.push(`/dashboard/${value}`);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="select-label">{t('shared.labels.dashboardType')}</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={viewMode}
        label="Selected"
        onChange={event => handleChange(event.target.value as DashboardViewMode)}
      >
        {showGeneral && <MenuItem value={'general'}>{t('shared.labels.general')}</MenuItem>}
        <MenuItem value={'detailed'}>{t('shared.labels.detailed')}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default DashboardSelector;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);
