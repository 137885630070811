import React from 'react';
import { Column, Order } from '../../../types';
import {
  TableHead,
  withStyles,
  Theme,
  createStyles,
  TableCell,
  TableRow,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import { useReport } from '../../../context/report';

interface Header<T extends { [key: string]: string | number | undefined }> {
  columns: Column<T>[];
  totals?: T;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: Order;
  orderBy: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      whiteSpace: 'nowrap',
    },
  })
)(TableCell);

const TotalCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.type === 'light' ? '#F5F5F5' : '#515151',
      top: '38px',
    },
  })
)(TableCell);

const Header = <T extends { [key: string]: string | number | undefined }>({
  columns,
  totals,
  order,
  orderBy,
  onRequestSort,
}: Header<T>) => {
  const classes = useStyles();
  const { frequency } = useReport();
  // Horrible, pero los nombres vienen así.
  const getColumnField = (field: string) => {
    if (field !== 'time') return field;
    else return frequency;
  };
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property !== 'time' ? property : frequency);
  };
  return (
    <TableHead>
      <TableRow>
        {columns.map(column => (
          <StyledTableCell
            key={column.field}
            align={column.align}
            sortDirection={orderBy === column.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === getColumnField(column.field)}
              direction={orderBy === getColumnField(column.field) ? order : 'asc'}
              onClick={createSortHandler(column.field)}
            >
              {column.label}
              {orderBy === getColumnField(column.field) ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
      {totals && (
        <TableRow>
          <TotalCell>{'Total'}</TotalCell>
          {columns.slice(1).map(column => (
            <TotalCell key={`total-row-${column.field}`} align={column.align}>
              {totals[column.field] &&
                (column.format ? column.format(totals) : totals[column.field])}
            </TotalCell>
          ))}
        </TableRow>
      )}
    </TableHead>
  );
};

export default Header;
